import Cookies from 'js-cookie';
import React, { Component } from 'react';

import {
  Menu,
  Grid,
  Form,
  Message,
  Header,
  Button,
  Segment,
} from 'semantic-ui-react';

import { getCookieDomain } from '../helpers';
import API from '../helpers/api';
import ResponsiveContainer from './ResponsiveContainer';

const buttonStyle = {
  backgroundColor: 'white',
  borderColor: 'gray',
  borderWidth: 1,
  borderStyle: 'solid',
};

export default class SignUp extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    repeatPassword: '',
    phoneNumber: '',
    dateOfBirth: '',
    gender: '',
    emailNotifications: false,
    termsAndConditions: false,
    isLoading: false,
    error: null,
    width: null,
  };

  constructor(props) {
    super(props);

    this.state.width = window.innerWidth;
  }

  handleGenderButtonPress = (e) => {
    e.preventDefault();

    this.setState({ gender: e.target.value });
  };

  handleFieldChange = (e, data) => {
    this.setState({ [data.name]: data.value });
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  // handleDateSelect = date => {
  //   // this.setState({ dateOfBirth: moment(date).format("DD.MM.YYYY") });
  // };

  handleSubmit = () => {
    const {
      firstName,
      lastName,
      email,
      password,
      repeatPassword,
      phoneNumber,
      dateOfBirth,
      gender,
      emailNotifications,
      termsAndConditions,
    } = this.state;

    const input = {
      firstName,
      lastName,
      email,
      password,
      phoneNumber,
      dateOfBirth,
      gender,
      emailNotifications,
    };

    if (password.length < 6) {
      this.setState({ error: 'Моля парола съдържаща поне шест символа.' });
      return;
    }

    if (password !== repeatPassword) {
      this.setState({ error: 'Моля въведете съвпадащи се пароли.' });
      return;
    }

    if (!termsAndConditions) {
      this.setState({
        error:
          'Моля запознайте се с условията за ползване. Не можете да продължите ако не сте съгласни с условията за ползване.',
      });
      return;
    }

    this.setState({ isLoading: true });

    const body = JSON.stringify(input);

    API.post('signup', { body }).then(({ json: data, response }) => {
      if (response.ok) {
        const domain = getCookieDomain();
        Cookies.set('OAUTH_TOKEN', data.accessToken, { expires: 1, domain }); //, secure: !global.__DEV__});
        Cookies.set('USER_ID', data.id, { expires: 1, domain }); //, secure: !global.__DEV__});

        this.props.router.push('/checkout');
      } else {
        this.setState({
          password: '',
          repeatPassword: '',
          error: data.error,
          isLoading: false,
        });
      }
    });
  };

  renderMenuItems = (isMobile) => {
    const items = [];
    if (!isMobile) {
      items.push(
        <Menu.Item key="logo" link href="/">
          <img
            // style={{ height: 10, padding: 0 }}
            src={isMobile ? '/logo-full.png' : '/logo.png'}
            alt="logo"
          />
        </Menu.Item>
      );
    }

    return [
      ...items,
      <Menu.Item key="signUp">
        <Header as="h2" style={{ color: isMobile ? 'white' : 'black' }}>
          Регистрация
        </Header>
      </Menu.Item>,
    ];
  };

  handleUpdatedWidth = (e, { width }) => {
    if (this.state.isMinimized && width > 1031) {
      return this.setState({ isMinimized: false, width });
    }

    this.setState({ width });
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      password,
      repeatPassword,
      phoneNumber,
      dateOfBirth,
      gender,
      emailNotifications,
      termsAndConditions,
      error,
      width,
    } = this.state;

    const selectedButtonStyleProps = {
      color: 'blue',
    };

    const buttonStyleProps = {
      style: buttonStyle,
    };

    const isMobile = width && width < 1031;

    return (
      <ResponsiveContainer
        style={{
          flex: 1,
          minHeight: '100vh',
          overflow: 'hidden',
        }}
        menuItems={this.renderMenuItems(isMobile)}
        onUpdate={this.handleUpdatedWidth}
      >
        {/* {renderHeaderMenu()} */}
        <Grid
          // textAlign="center"
          centered
          style={{ margin: 0, flex: 1 }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 800 }}>
            {/* TODO: Add a greeting message */}
            <Form onSubmit={this.handleSubmit} error={!!error}>
              <Segment stacked>
                <Message error header="Грешка" content={this.state.error} />
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    name="firstName"
                    label="Име"
                    placeholder="Име"
                    onChange={this.handleFieldChange}
                    value={firstName}
                    required
                  />
                  <Form.Input
                    fluid
                    name="lastName"
                    label="Фамилия"
                    placeholder="Фамилия"
                    onChange={this.handleFieldChange}
                    value={lastName}
                    required
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    autoComplete="username"
                    name="email"
                    label="Имейл"
                    placeholder="Имейл"
                    onChange={this.handleEmailChange}
                    value={email}
                    required
                  />
                  <Form.Input
                    fluid
                    name="phoneNumber"
                    label="Телефон за връзка"
                    onChange={this.handleFieldChange}
                    value={phoneNumber}
                    placeholder="Телефонен номер"
                    type="number"
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    required
                    autoComplete="new-password"
                    name="password"
                    label="Парола"
                    placeholder="Минимум 6 символа"
                    onChange={this.handleFieldChange}
                    value={password}
                    type="password"
                  />
                  <Form.Input
                    fluid
                    required
                    autoComplete="new-password"
                    name="repeatPassword"
                    label="Потвърждаване на парола"
                    placeholder="Потвърдете паролата"
                    onChange={this.handleFieldChange}
                    value={repeatPassword}
                    type="password"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Input
                    width={8}
                    fluid
                    type="date"
                    name="dateOfBirth"
                    label="Дата на раждане"
                    onChange={this.handleFieldChange}
                    value={dateOfBirth}
                    placeholder="Дата"
                  />
                  <Form.Field width={8} style={{ paddingLeft: 10 }}>
                    <label>Пол</label>
                    <Form.Group>
                      <Form.Button
                        {...(gender === 'male'
                          ? selectedButtonStyleProps
                          : buttonStyleProps)}
                        value="male"
                        onClick={this.handleGenderButtonPress}
                      >
                        Мъж
                      </Form.Button>
                      <Form.Button
                        {...(gender === 'female'
                          ? selectedButtonStyleProps
                          : buttonStyleProps)}
                        value="female"
                        onClick={this.handleGenderButtonPress}
                      >
                        Жена
                      </Form.Button>
                      <Form.Button
                        {...(gender === 'other'
                          ? selectedButtonStyleProps
                          : buttonStyleProps)}
                        value="other"
                        onClick={this.handleGenderButtonPress}
                      >
                        Друг
                      </Form.Button>
                    </Form.Group>
                  </Form.Field>
                </Form.Group>
                <Form.Group
                  grouped
                  style={{
                    textAlign: 'center',
                    paddingTop: 15,
                    paddingBottom: 15,
                  }}
                >
                  <Form.Checkbox
                    required
                    onChange={(e, { checked }) =>
                      this.setState({ termsAndConditions: checked })
                    }
                    checked={termsAndConditions}
                    label="Прочетох условията за ползване и съм съгласен/на с тях."
                  />
                  <Form.Checkbox
                    onChange={(e, { checked }) =>
                      this.setState({ emailNotifications: checked })
                    }
                    checked={emailNotifications}
                    name="emailNotifications"
                    label="Искам да получавам известия по имейл относто моята консултации (препоръчително)."
                  />
                </Form.Group>
                <Button color="blue" fluid size="large">
                  Регистрирай се
                </Button>
              </Segment>
            </Form>
            <Message style={{ textAlign: 'center' }}>
              Вече сте регистрирани? <a href="/login">Влезте в акаунта си.</a>
            </Message>
          </Grid.Column>
        </Grid>
      </ResponsiveContainer>
    );
  }
}
