import graphql from 'babel-plugin-relay/macro';
import React, { Component } from 'react';
import { Table, Icon } from 'semantic-ui-react';
import { createFragmentContainer } from 'react-relay';
import styled from 'styled-components';
import moment from 'moment';

const Container = styled.div`
  min-height: calc(100vh - 93px);
`;

class Files extends Component {
  render() {
    const { edges } = this.props.files;

    return (
      <Container style={{ ...{ width: this.props.width * 0.995 } }}>
        <Table style={{ marginTop: 10 }} celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No.</Table.HeaderCell>
              <Table.HeaderCell>Име</Table.HeaderCell>
              <Table.HeaderCell onClick={() => this.props.onClick()}>
                Създаден на
                <Icon name="sort" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {edges.map(({ node }) => (
              <Table.Row key={node.rowId}>
                <Table.Cell>{node.rowId}</Table.Cell>
                <Table.Cell>
                  <a
                    href={`//${window.SERVER_URL}/attachment/${node.rowId}`}
                    download
                  >
                    {node.name}
                  </a>
                </Table.Cell>
                <Table.Cell>{moment(node.createdAt).format('LLL')}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

export default createFragmentContainer(
  Files,
  graphql`
    fragment Files_files on FileConnection {
      edges {
        node {
          rowId
          name
          createdAt
        }
      }
    }
  `
);
