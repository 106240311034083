import PropTypes from 'prop-types';

import React, { Component } from 'react';
import {
  Responsive,
  Sidebar,
  Menu,
  Segment,
  Container,
  Icon
} from 'semantic-ui-react';

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children, menuItems } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Responsive
        as={Sidebar.Pushable}
        maxWidth={1031}
        onUpdate={this.props.onUpdate}
        style={{
          minHeight: 'calc(100vh - 72px)',
          overflow: 'hidden'
        }}
      >
        <Sidebar
          as={Menu}
          animation="push"
          inverted
          // TODO: Handle mobile clicks
          onHide={this.handleSidebarHide}
          vertical={true}
          visible={sidebarOpened}
        >
          {menuItems}
        </Sidebar>

        <Sidebar.Pusher
          dimmed={sidebarOpened}
          onClick={() =>
            sidebarOpened && this.setState({ sidebarOpened: false })
          }
        >
          <Segment
            textAlign="center"
            style={{ padding: '0.2em 0em', height: 72 }}
            vertical={true}
          >
            <Container>
              <Menu inverted secondary size="large" style={{ height: 72 }}>
                <Menu.Item onClick={this.handleToggle} size="big">
                  <Icon name="sidebar" color="blue" size="big" />
                </Menu.Item>
                <Menu.Item size="large" link href="/">
                  <img
                    src="/logo-full.png"
                    alt="logo"
                    style={{
                      height: 40,
                      width: 'auto',
                      marginBottom: 8
                      // marginTop: 5
                    }}
                  />
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>
          {children}
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node
};

export default MobileContainer;
