import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Header,
  Grid,
  Form,
  Message,
  Input,
  Button,
  Modal,
  Segment,
  ModalActions,
} from 'semantic-ui-react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'found';
import { UpdateUserMutation } from '../mutations';

const Container = styled.div`
  min-height: calc(100vh - 69px);
  flex: 1;
  padding-bottom: 74px;
  // overflow: hidden;
`;

class MyProfile extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    validatePassword: '',
    confirmPassword: '',
    password: '',
    phoneNumber: '',
    isLoading: false,
    isModalOpen: false,
    error: null,
  };

  constructor(props) {
    super(props);

    const { firstName, lastName, email, phoneNumber } = this.props.viewer;

    this.state = {
      ...this.state,
      firstName,
      lastName,
      email,
      phoneNumber,
    };
  }

  handleFieldChange = (i, data) => {
    this.setState({ [data.name]: data.value });
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value.toLowerCase() });
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  handleFormSubmit = () => {
    const { password, confirmPassword } = this.state;

    if (password) {
      if (password !== confirmPassword) {
        return alert('Моля въведете съвпадащи се пароли!');
      } else if (password.length < 6) {
        return alert('Моля въведете парола по-дълга от 6 символа');
      }
    }

    return this.setState({ isModalOpen: true });
  };

  handlePasswordSubmit = () => {
    const {
      password,
      confirmPassword,
      firstName,
      lastName,
      email,
      validatePassword,
      phoneNumber,
    } = this.state;

    const input = {
      id: this.props.viewer.id,
      validatePassword,
      password,
      email,
      firstName,
      lastName,
      phoneNumber,
    };

    if (password !== confirmPassword) {
      this.setState({ error: 'Моля въведете съвпадащи се пароли.' });
      return;
    }

    const onSuccess = (data) => {
      this.props.router.push('/');
    };

    const onFailure = (errors) => {
      const { res } = JSON.parse(JSON.stringify(errors));

      const _error = res.errors && res.errors[0];
      let error;
      if (_error) {
        switch (_error.message) {
          case 'Incorrect Password!':
            error = 'Грешна парола!';
            break;
          case 'User Already Exists!':
            error = 'Потребител с този имейл вече съществува!';
            break;
          default:
            error = 'Грешка!';
        }
      }
      this.setState({
        validatePassword: '',
        error,
      });
    };

    UpdateUserMutation({ input }, onSuccess, onFailure);
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      confirmPassword,
      validatePassword,
      phoneNumber,
      password,
      isModalOpen,
      error,
    } = this.state;
    const { viewer } = this.props;

    const disabled = !(
      firstName !== viewer.firstName ||
      lastName !== viewer.lastName ||
      email !== viewer.email ||
      phoneNumber !== viewer.phoneNumber ||
      (password.length >= 6 && confirmPassword.length >= 6)
    );

    return (
      <Container>
        <Header style={{ paddingLeft: 20, marginTop: 20 }} as="h2">
          Редактиране на профил
        </Header>
        <Segment padded basic>
          <Grid columns={1}>
            <Grid.Column>
              <Form onSubmit={this.handleFormSubmit}>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    name="firstName"
                    label="Име"
                    onChange={this.handleFieldChange}
                    value={firstName}
                  />
                  <Form.Input
                    fluid
                    name="lastName"
                    label="Фамилия"
                    onChange={this.handleFieldChange}
                    value={lastName}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    autoComplete="username"
                    name="email"
                    type={viewer.type !== 'admin' ? 'email' : undefined}
                    label="Имейл"
                    onChange={this.handleEmailChange}
                    value={email}
                  />
                  <Form.Input
                    fluid
                    name="phoneNumber"
                    label="Телефон за връзка"
                    onChange={this.handleFieldChange}
                    value={phoneNumber}
                    type="number"
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    autoComplete="new-password"
                    name="password"
                    label="Нова парола"
                    placeholder="Минимум 6 символа"
                    onChange={this.handleFieldChange}
                    value={password}
                    type="password"
                  />

                  <Form.Input
                    fluid
                    autoComplete="new-password"
                    name="confirmPassword"
                    label="Потвърдете паролата"
                    placeholder="Потвърдете паролата"
                    onChange={this.handleFieldChange}
                    value={confirmPassword}
                    type="password"
                  />
                </Form.Group>
                <Form.Button
                  disabled={disabled}
                  style={{ marginTop: 5 }}
                  color="blue"
                >
                  Запазете промените
                </Form.Button>
              </Form>
            </Grid.Column>
            <Grid.Column />
          </Grid>
        </Segment>
        <Modal
          size={'small'}
          // style={{ height: 200, width: 600 }}
          open={isModalOpen}
          closeIcon
          closeOnDimmerClick={false}
          onClose={() => this.setState({ isModalOpen: false })}
        >
          <Modal.Header>
            За да запазите промените, моля въведете текущата си парола
          </Modal.Header>
          {/* <Modal.Content>
                  Моля въведете номер на касовата бележка.
                </Modal.Content> */}
          <Modal.Content>
            {error && (
              <Message error header={error} content="Моля опитайте пак." />
            )}
            <Input
              fluid
              required
              autoComplete="current-password"
              icon="lock"
              name="validatePassword"
              placeholder={'Парола'}
              value={validatePassword}
              onChange={this.handleFieldChange}
              type="password"
              style={{ width: '100%' }}
            />
          </Modal.Content>
          <ModalActions>
            <Button
              color="blue"
              style={{ marginTop: 5 }}
              onClick={this.handlePasswordSubmit}
              size="small"
            >
              Потвърди
            </Button>
          </ModalActions>
        </Modal>
      </Container>
    );
  }
}

export default createFragmentContainer(
  withRouter(MyProfile),
  graphql`
    fragment MyProfileForm_viewer on User {
      id
      type
      firstName
      lastName
      phoneNumber
      email
    }
  `
);
