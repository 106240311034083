import graphql from "babel-plugin-relay/macro";

const MyProfileQuery = graphql `
    query MyProfileQuery_Query {
        viewer {
            ...MyProfileForm_viewer
        }
    }
`;

export default MyProfileQuery;