import graphql from 'babel-plugin-relay/macro';
import React, { Component } from 'react';
import { Table, Icon } from 'semantic-ui-react';
import { createRefetchContainer } from 'react-relay';
import moment from 'moment';
import styled from 'styled-components';

const Container = styled.div`
  min-height: calc(100vh - 93px);
`;

class Threads extends Component {
  render() {
    const { edges } = this.props.threads;

    return (
      <Container style={{ ...{ width: this.props.width * 0.995 } }}>
        <Table style={{ marginTop: 10 }} celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No.</Table.HeaderCell>
              <Table.HeaderCell>Заглавие</Table.HeaderCell>
              <Table.HeaderCell onClick={() => this.props.onClick()}>
                Създадена на
                <Icon name="sort" />
              </Table.HeaderCell>
              <Table.HeaderCell>Статус</Table.HeaderCell>
              <Table.HeaderCell>Тип</Table.HeaderCell>
              <Table.HeaderCell>Плащане</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {edges.map(({ node }) => (
              <Table.Row key={node.rowId}>
                <Table.Cell
                  onMouseEnter={() => {
                    document.body.style.cursor = 'pointer';
                  }}
                  onMouseLeave={() => {
                    document.body.style.cursor = 'default';
                  }}
                  width={1}
                  onClick={() => this.props.onThreadClick(node)}
                >
                  {node.rowId}
                </Table.Cell>
                <Table.Cell
                  onMouseEnter={() => {
                    document.body.style.cursor = 'pointer';
                  }}
                  onMouseLeave={() => {
                    document.body.style.cursor = 'default';
                  }}
                  width={4}
                  onClick={() => this.props.onThreadClick(node)}
                >
                  {node.title}
                  {node.title && (
                    <Icon
                      name="eye"
                      color={'blue'}
                      style={{ marginLeft: 10 }}
                    />
                  )}
                </Table.Cell>
                <Table.Cell width={4}>
                  {moment(node.createdAt).format('LL')}
                </Table.Cell>
                {node.isActive && <Table.Cell width={2}>Активна</Table.Cell>}
                {!node.isActive && <Table.Cell>Приключена</Table.Cell>}
                <Table.Cell width={2}>{node.type}</Table.Cell>
                <Table.Cell
                  onMouseEnter={() => {
                    document.body.style.cursor = 'pointer';
                  }}
                  onMouseLeave={() => {
                    document.body.style.cursor = 'default';
                  }}
                  width={2}
                  onClick={() => this.props.onPaymentClick({ node })}
                >
                  {node.paymentId}
                  {node.paymentId && (
                    <Icon
                      name="eye"
                      color={'blue'}
                      style={{ marginLeft: 10 }}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

export default createRefetchContainer(
  Threads,
  graphql`
    fragment Threads_threads on ThreadConnection {
      edges {
        node {
          id
          rowId
          title
          createdAt
          type
          isActive
          paymentId
          ...Consultation_thread
        }
      }
    }
  `
);
