import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { throttle } from 'lodash';
import { createPaginationContainer } from 'react-relay';
import {
  Menu,
  Sidebar,
  Card,
  Segment,
  Icon,
  Container,
  Select,
  Grid,
  Label,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { withRelay } from '../relay';
import moment from 'moment';

const filterOptions = [
  {
    key: 'all',
    value: 'all',
    text: 'Всички',
  },
  {
    key: 'active',
    value: true,
    text: 'Активни',
  },
  {
    key: 'nonactive',
    value: false,
    text: 'Неактивни',
  },
];

const orderOptions = [
  {
    key: 'newest',
    value: 'DESC',
    text: 'Най-нови',
  },
  { key: 'oldest', value: 'ASC', text: 'Най-стари' },
];

const CenteredDiv = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const COUNT = 5;
const INITIAL_COUNT = 15;
const LIMIT = 1000;

class ConsultationsSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilter: this.props.selectedFilter,
      selectedOrder: this.props.selectedOrder,
    };

    this.refetchVariables = {
      count: INITIAL_COUNT,
      orderBy: [['createdAt', 'DESC']],
      filterBy: {
        isActive: null,
      },
    };

    this.height = 0;
    this.throttleScroll = throttle(this.handleScroll, 300);
  }

  handleOrderChange = (e, { value }) => {
    const { selectedFilter } = this.state;
    this.setState({ selectedOrder: value });

    this.refetchVariables = {
      ...this.refetchVariables,
      orderBy: [['createdAt', value]],
      filterBy: { isActive: selectedFilter === 'all' ? null : selectedFilter },
      count: INITIAL_COUNT,
    };

    this.props.relay.refetchConnection(
      INITIAL_COUNT,
      (error) => {
        error && console.log('error', error);
      },
      this.refetchVariables
    );
  };

  handleFilterChange = (e, { value }) => {
    const { selectedOrder } = this.state;
    this.setState({ selectedFilter: value });

    this.refetchVariables = {
      ...this.refetchVariables,
      orderBy: [['createdAt', selectedOrder]],
      filterBy: { isActive: value === 'all' ? null : value },
      count: INITIAL_COUNT,
    };

    this.refetchVariables.count = INITIAL_COUNT;
    this.props.relay.refetchConnection(
      INITIAL_COUNT,
      (error) => {
        error && console.log('error', error);
      },
      this.refetchVariables
    );
  };

  handleScroll = (e) => {
    var nearBottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 300;
    if (!this.props.relay.isLoading() && nearBottom) {
      this.refetchVariables.count = COUNT;
      if (this.props.relay.hasMore()) {
        this.props.relay.loadMore(COUNT, (error) => {
          console.log('error', error);
        });
      }
    }
  };

  onScroll = (e) => {
    e.persist();

    this.throttleScroll(e);
  };

  renderMenuItem = (thread) => {
    const threadsLength = this.props.viewer.threads.edges.length;
    const navbarHeight = 72;
    const filterHeight = 66;
    const menuItemHeight = 113;
    const hasScroll =
      navbarHeight + filterHeight + threadsLength * menuItemHeight >
      window.innerHeight;

    const {
      title,
      user,
      service,
      isActive,
      hasUnseenResponses,
      createdAt,
    } = thread;
    const { firstName, lastName } = user;

    const { selectedFilter, selectedOrder } = this.state;
    const { isMobile } = this.props;

    return (
      <Menu.Item
        style={{
          paddingLeft: !isMobile ? (hasScroll ? '19px' : '25px') : '',
        }}
        key={thread.id}
        onClick={() =>
          this.props.onClick(thread, selectedFilter, selectedOrder)
        }
        as="a"
      >
        <Card style={{ opacity: isActive ? 1 : 0.5 }}>
          <Card.Content textAlign="left">
            <Card.Header style={{ fontSize: isMobile ? 14 : 18 }}>
              {`${firstName} ${lastName}`}
            </Card.Header>
            <Card.Description style={{ fontSize: isMobile ? 13 : 14 }}>
              {service.name}{' '}
            </Card.Description>
            {hasUnseenResponses && (
              <Label
                style={{ backgroundColor: '#cc0000' }}
                size="mini"
                circular
                floating
                // color="red"
              />
            )}
            {!isMobile && (
              <div
                style={{
                  paddingTop: 5,
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
              >
                <Card.Meta>{title}</Card.Meta>
                <Card.Meta>
                  {moment(createdAt).format('DD-MM-YYYY HH:mm')}
                </Card.Meta>
              </div>
            )}
          </Card.Content>
        </Card>
      </Menu.Item>
    );
  };

  render() {
    const menuItems =
      this.props.viewer.threads &&
      this.props.viewer.threads.edges.map(({ node: thread }) =>
        this.renderMenuItem(thread)
      );



    const { isMobile, isMinimized } = this.props;
    const { selectedFilter, selectedOrder } = this.state;

    return (
      <Sidebar
        onScroll={this.onScroll}
        as={Menu}
        style={{
          backgroundColor: '#0892d1',
          overflow: 'hidden',
          borderWidth: 0,
        }}
        icon="labeled"
        onHide={this.handleSidebarHide}
        vertical={true}
        visible={true}
        width={isMobile ? (isMinimized ? 'very thin' : 'thin') : 'wide'}
      >
        {!isMinimized && (
          <Segment
            style={{
              borderRadius: 0,
              marginBottom: 0,
              backgroundColor: 'white',
            }}
          >
            {!isMobile && (
              <Grid>
                <Grid.Row>
                  <Grid.Column width="2" verticalAlign="middle">
                    <Icon name="filter" size="large" color="grey" />
                  </Grid.Column>
                  <Grid.Column
                    width="7"
                    style={{ paddingLeft: 7, paddingRight: 0 }}
                  >
                    <Select
                      fluid
                      compact
                      options={filterOptions}
                      value={selectedFilter}
                      onChange={this.handleFilterChange}
                    />
                  </Grid.Column>
                  <Grid.Column width="7">
                    <Select
                      fluid
                      compact
                      options={orderOptions}
                      value={selectedOrder}
                      onChange={this.handleOrderChange}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
            {isMobile && (
              <Grid>
                <Grid.Row>
                  <Grid.Column style={{ marginTop: -10, marginBottom: -10 }}>
                    <Select
                      fluid
                      compact
                      options={filterOptions}
                      value={selectedFilter}
                      onChange={this.handleFilterChange}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column style={{ marginTop: -10, marginBottom: -10 }}>
                    <Select
                      fluid
                      // compact
                      options={orderOptions}
                      value={selectedOrder}
                      onChange={this.handleOrderChange}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </Segment>
        )}
        {menuItems && menuItems.length ? (
          isMinimized ? (
            <CenteredDiv onClick={this.props.onEnlargeClick}>
              <Icon
                link
                name="arrow alternate circle right outline"
                size="big"
                inverted
              />
            </CenteredDiv>
          ) : (
            <Container>{menuItems}</Container>
          )
        ) : (
          selectedFilter === 'all' && (
            <p
              style={{
                position: 'relative',
                top: 20,
                width: '100%',
                overflow: 'hidden',
                margin: '0 auto',
                paddingLeft: 20,
                paddingRight: 20,
                left: 0,
                right: 0,
                color: 'white',
                fontSize: 18,
              }}
            >
              Нямате активни консултации. Можете да започнете нова консултации
              от менюто {isMobile ? `Нова консултация` : `горе`} .
            </p>
          )
        )}
      </Sidebar>
    );
  }
}

const Query = graphql`
  query ConsultationsSidebar_Query(
    $orderBy: [[String]]
    $filterBy: ThreadFilterInput
    $after: String
    $count: Int
    $limit: Int
  ) {
    viewer {
      ...ConsultationsSidebar_viewer
        @arguments(
          orderBy: $orderBy
          filterBy: $filterBy
          after: $after
          count: $count
          limit: $limit
        )
    }
  }
`;
const ConsultationsSidebarContainer = createPaginationContainer(
  ConsultationsSidebar,
  {
    viewer: graphql`
      fragment ConsultationsSidebar_viewer on User
        @argumentDefinitions(
          orderBy: { type: "[[String]]" }
          filterBy: { type: "ThreadFilterInput" }
          after: { type: "String" }
          count: { type: "Int" }
          limit: { type: "Int" }
        ) {
        threads(
          filterBy: $filterBy
          orderBy: $orderBy
          after: $after
          first: $count
          limit: $limit
        )
          @connection(
            key: "ConsultationsSidebar_threads"
            filters: ["filterBy"]
          ) {
          edges {
            node {
              id
              title
              isActive
              createdAt
              service {
                id
                name
              }
              user {
                id
                firstName
                lastName
                email
                phoneNumber
              }
              hasUnseenResponses
              ...Consultation_thread
            }
          }
          pageInfo {
            hasNextPage
            endCursor
            hasPreviousPage
            startCursor
          }
          pageInfo {
            hasNextPage
            endCursor
            hasPreviousPage
            startCursor
          }
        }
      }
    `,
  },

  {
    direction: 'forward',
    query: graphql`
      query ConsultationsSidebarForwardQuery(
        $orderBy: [[String]]
        $filterBy: ThreadFilterInput
        $after: String
        $count: Int
        $limit: Int
      ) {
        viewer {
          ...ConsultationsSidebar_viewer
            @arguments(
              orderBy: $orderBy
              filterBy: $filterBy
              after: $after
              count: $count
              limit: $limit
            )
        }
      }
    `,
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.threads;
    },
    getFragmentVariables(previousVariables, totalCount) {
      return {
        ...previousVariables,
        count: totalCount,
      };
    },
    getVariables(props, paginationInfo, fragmentVariables) {
      let { selectedFilter } = props;
      if (props.selectedFilter === 'all') {
        selectedFilter = null;
      }

      return {
        count: paginationInfo.count,
        after: paginationInfo.cursor,
        limit: LIMIT,
        orderBy: [['createdAt', props.selectedOrder]],
        filterBy: { isActive: selectedFilter },
      };
    },
  }
);

ConsultationsSidebarContainer.getVariables = (props) => {
  let { selectedFilter } = props;
  if (props.selectedFilter === 'all') {
    selectedFilter = null;
  }

  return {
    filterBy: { isActive: selectedFilter },
    orderBy: [['createdAt', props.selectedOrder]],
    count: INITIAL_COUNT,
    limit: LIMIT,
  };
};

export default withRelay(ConsultationsSidebarContainer, Query);
