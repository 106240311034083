import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';

import { environment } from '../relay';
// import { UserType } from "../../../server/src/graphql/types/User";

const mutation = graphql`
  mutation UpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      viewer {
        ...MyProfileForm_viewer
      }
    }
  }
`;

export default (payload, success, failure) => {
  const { input } = payload;

  commitMutation(environment, {
    mutation,
    variables: {
      input,
    },
    onCompleted: (response, errors) =>
      errors && errors.length ? failure(errors) : success(response),
    onError: (err) => {
      failure(err);
    },
  });
};
