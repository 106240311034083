/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ConsultationForm_viewer$ref: FragmentReference;
export type ConsultationForm_viewer = {|
  +id: string,
  +rowId: ?number,
  +type: ?string,
  +payment: ?{|
    +id: string,
    +rowId: number,
    +userId: ?number,
    +service: ?{|
      +id: string,
      +rowId: number,
      +shortPrompt: ?string,
      +longPrompt: ?string,
    |},
  |},
  +$refType: ConsultationForm_viewer$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ConsultationForm_viewer",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "paymentId",
      "type": "String"
    }
  ],
  "selections": [
    v0,
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "type",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "payment",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "paymentId",
          "type": "String"
        }
      ],
      "concreteType": "Payment",
      "plural": false,
      "selections": [
        v0,
        v1,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "userId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "service",
          "storageKey": null,
          "args": null,
          "concreteType": "Service",
          "plural": false,
          "selections": [
            v0,
            v1,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "shortPrompt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "longPrompt",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e2ae126aab7ee54c204768cad29d4e09';
module.exports = node;
