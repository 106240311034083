/**
 * @flow
 * @relayHash 5ede0a4f358e9e1d01734046706d121b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type NotesSidebar_viewer$ref = any;
export type NoteFilterInput = {
  userId?: ?string
};
export type NotesSidebar_QueryVariables = {|
  orderBy?: ?$ReadOnlyArray<?$ReadOnlyArray<?string>>,
  filterBy?: ?NoteFilterInput,
  after?: ?string,
  count?: ?number,
|};
export type NotesSidebar_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: NotesSidebar_viewer$ref
  |}
|};
export type NotesSidebar_Query = {|
  variables: NotesSidebar_QueryVariables,
  response: NotesSidebar_QueryResponse,
|};
*/


/*
query NotesSidebar_Query(
  $orderBy: [[String]]
  $filterBy: NoteFilterInput
  $after: String
  $count: Int
) {
  viewer {
    ...NotesSidebar_viewer_23g53P
    id
  }
}

fragment NotesSidebar_viewer_23g53P on User {
  id
  patientNotes(filterBy: $filterBy, orderBy: $orderBy, after: $after, first: $count) {
    edges {
      node {
        id
        rowId
        title
        userId
        createdAt
        updatedAt
        text
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
      hasPreviousPage
      startCursor
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[[String]]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filterBy",
    "type": "NoteFilterInput",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "filterBy",
    "variableName": "filterBy",
    "type": "NoteFilterInput"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy",
    "type": "[[String]]"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "NotesSidebar_Query",
  "id": null,
  "text": "query NotesSidebar_Query(\n  $orderBy: [[String]]\n  $filterBy: NoteFilterInput\n  $after: String\n  $count: Int\n) {\n  viewer {\n    ...NotesSidebar_viewer_23g53P\n    id\n  }\n}\n\nfragment NotesSidebar_viewer_23g53P on User {\n  id\n  patientNotes(filterBy: $filterBy, orderBy: $orderBy, after: $after, first: $count) {\n    edges {\n      node {\n        id\n        rowId\n        title\n        userId\n        createdAt\n        updatedAt\n        text\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n      hasPreviousPage\n      startCursor\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NotesSidebar_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "NotesSidebar_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "after",
                "variableName": "after",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "filterBy",
                "variableName": "filterBy",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "orderBy",
                "variableName": "orderBy",
                "type": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NotesSidebar_Query",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "patientNotes",
            "storageKey": null,
            "args": v2,
            "concreteType": "NoteConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "NoteEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Note",
                    "plural": false,
                    "selections": [
                      v1,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "rowId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "userId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updatedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "text",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "UFPageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasPreviousPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "patientNotes",
            "args": v2,
            "handle": "connection",
            "key": "NotesSidebar_patientNotes",
            "filters": [
              "filterBy"
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fb6c8a7344dae2fcc3c681150d4ffb62';
module.exports = node;
