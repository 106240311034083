import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "../relay";
import SharedUpdater from "../helpers/SharedUpdater";

const mutation = graphql`
  mutation DeleteNoteMutation($input: DeleteNoteInput!) {
    deleteNote(input: $input) {
      clientMutationId
    }
  }
`;

export default (payload, success, failure) => {
  const { connectionName, input, viewer, filters } = payload;

  commitMutation(environment, {
    mutation,
    variables: {
      input
    },
    updater: store => {
      const payload = store.getRootField("deleteNote");
      if (!payload) {
        return;
      }

      SharedUpdater.deleteEdgeFromConnection(
        store,
        viewer.id,
        input.id,
        connectionName,
        filters
      );
    },
    onCompleted: (response, errors) =>
      errors && errors.length ? failure(errors) : success(response),
    onError: err => failure(err)
  });
};
