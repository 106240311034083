/**
 * @flow
 * @relayHash 5d46143d68c140e82f6e6b3501260924
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Payments_viewer$ref = any;
export type PaymentFilterInput = {
  date?: ?RangeDateFilter,
  type?: ?string,
  status?: ?string,
  paymentId?: ?string,
  userId?: ?string,
};
export type RangeDateFilter = {
  from?: ?string,
  to?: ?string,
};
export type PaymentsRefetchQueryVariables = {|
  orderBy?: ?$ReadOnlyArray<?$ReadOnlyArray<?string>>,
  filterBy?: ?PaymentFilterInput,
  page?: ?number,
  limit?: ?number,
  first?: ?number,
|};
export type PaymentsRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: Payments_viewer$ref
  |}
|};
export type PaymentsRefetchQuery = {|
  variables: PaymentsRefetchQueryVariables,
  response: PaymentsRefetchQueryResponse,
|};
*/


/*
query PaymentsRefetchQuery(
  $orderBy: [[String]]
  $filterBy: PaymentFilterInput
  $page: Int
  $limit: Int
) {
  viewer {
    ...Payments_viewer_18RA4p
    id
  }
}

fragment Payments_viewer_18RA4p on User {
  type
  payments(orderBy: $orderBy, filterBy: $filterBy, first: 10000, page: $page, limit: $limit) {
    edges {
      node {
        id
        ...PaymentRow_payment
        __typename
      }
      cursor
    }
    currentPage
    total
    start
    end
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}

fragment PaymentRow_payment on Payment {
  id
  rowId
  status
  user {
    firstName
    lastName
    id
  }
  type
  amount
  createdAt
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[[String]]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filterBy",
    "type": "PaymentFilterInput",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "page",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "limit",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "filterBy",
    "variableName": "filterBy",
    "type": "PaymentFilterInput"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000,
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy",
    "type": "[[String]]"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page",
    "type": "Int"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "PaymentsRefetchQuery",
  "id": null,
  "text": "query PaymentsRefetchQuery(\n  $orderBy: [[String]]\n  $filterBy: PaymentFilterInput\n  $page: Int\n  $limit: Int\n) {\n  viewer {\n    ...Payments_viewer_18RA4p\n    id\n  }\n}\n\nfragment Payments_viewer_18RA4p on User {\n  type\n  payments(orderBy: $orderBy, filterBy: $filterBy, first: 10000, page: $page, limit: $limit) {\n    edges {\n      node {\n        id\n        ...PaymentRow_payment\n        __typename\n      }\n      cursor\n    }\n    currentPage\n    total\n    start\n    end\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n\nfragment PaymentRow_payment on Payment {\n  id\n  rowId\n  status\n  user {\n    firstName\n    lastName\n    id\n  }\n  type\n  amount\n  createdAt\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "PaymentsRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Payments_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "filterBy",
                "variableName": "filterBy",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "limit",
                "variableName": "limit",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "orderBy",
                "variableName": "orderBy",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "page",
                "variableName": "page",
                "type": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PaymentsRefetchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "payments",
            "storageKey": null,
            "args": v2,
            "concreteType": "PaymentConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "PaymentEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Payment",
                    "plural": false,
                    "selections": [
                      v3,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "rowId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "user",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "lastName",
                            "args": null,
                            "storageKey": null
                          },
                          v3
                        ]
                      },
                      v1,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "amount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "currentPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "total",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "start",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "end",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "UFPageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "payments",
            "args": v2,
            "handle": "connection",
            "key": "Payment_payments",
            "filters": [
              "filterBy"
            ]
          },
          v3
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c1aa4f8f5750bda861cc9508cfefa7b9';
module.exports = node;
