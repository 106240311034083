import graphql from 'babel-plugin-relay/macro';
import Cookies from 'js-cookie';
import moment from 'moment';
import 'moment/locale/bg';
import momentTimezone from 'moment-timezone';
import React, { Component } from 'react';
import { createFragmentContainer } from 'react-relay';
import styled from 'styled-components';
import '../styles/app.css';
import 'semantic-ui-css/semantic.min.css';
import Navbar from './Navbar';

import CookieBanner from './CookieBanner';

// import Login from "./Login";
// import Consultations from "./Consultations";
// import NewConsultationForm from "./NewConsultationForm";
// import Registration from "./Registration";

// import "../styles/semantic/semantic.js";
// import "../styles/semantic/semantic.css";
// import "../styles/contextMenu/contextMenu.css";

const Container = styled.div`
  height: 100vh;
  flex: 1;
`;

// const message =
//   "Buildo uses cookies to guarantee users the employment of its site features, offering a better purchasing experience. By continuing to browse the site you're agreeing to our use of cookies.";

class App extends Component {
  constructor(props) {
    super(props);

    momentTimezone().tz('Europe/Sofia');
    moment.locale('bg');
  }

  componentDidMount() {
    if ('ontouchstart' in document.documentElement) {
      document.body.style.cursor = 'pointer';
    }
  }

  render() {
    const { children, viewer = null } = this.props;

    if (viewer && !viewer.rowId) {
      const accessToken = Cookies.get('OAUTH_TOKEN');
      if (accessToken) {
        this.props.router.replace('/logout');
        return null;
      }
    }

    return (
      <Container>
        <Navbar>
          {React.Children.map(children, child =>
            React.cloneElement(child, { user: viewer })
          )}
        </Navbar>
        <CookieBanner />
      </Container>
    );
  }
}

export default createFragmentContainer(
  App,
  graphql`
    fragment App_viewer on User {
      type
      id
      rowId
    }
  `
);
