import graphql from "babel-plugin-relay/macro";

const PatientsQuery = graphql`
  query PatientsQuery_Query(
    $orderBy: [[String]]
    $filterBy: UsersFilterInput
    $page: Int
    $limit: Int
    $first: Int
  ) {
    viewer {
      ...Patients_viewer
        @arguments(
          orderBy: $orderBy
          filterBy: $filterBy
          page: $page
          limit: $limit
          first: $first
        )
    }
  }
`;

export default PatientsQuery;
