import React, { Component } from 'react';
import styled from 'styled-components';
import { Icon, Segment, Sidebar, Header } from 'semantic-ui-react';

import Note from './Note';
import NotesSidebar from './NotesSidebar';

const Container = styled.div`
  min-height: calc(100vh - 72px);
  margin-top: 1px;
`;

const Container2 = styled.div`
  height: calc(100vh - 72px);
  overflow: hidden;
`;

const placeholderStyle = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)'
};

class Notes extends Component {
  state = {
    note: null,
    isMinimized: false,
    width: null,
    height: null
  };

  constructor(props) {
    super(props);

    this.state.width = window.innerWidth;
    this.state.height = window.innerHeight;
  }

  handleNoteClick = note => {
    const { width } = this.state;
    const isMobile = width && width < 1031;

    if (isMobile) {
      return this.setState({ note, isMinimized: true });
    }

    this.setState({ note });
  };

  handleEnlargeClick = () => {
    this.setState({ isMinimized: false });
  };

  handleUpdatedWidth = (e, { width, height }) => {
    if (this.state.isMinimized && width > 1031) {
      return this.setState({ isMinimized: false, width, height });
    }

    this.setState({ width, height });
  };

  render() {
    const { note, width, isMinimized, height } = this.state;
    const isMobile = width && width < 1031;

    return (
      <Container style={{ ...{ width: this.props.width * 0.995 } }}>
        <Container2 style={{ ...{ width: this.props.width * 0.995 } }}>
          <Sidebar.Pushable
            as={Segment}
            width="thin"
            style={{
              margin: 0,
              padding: 0,
              borderWidth: 0
            }}
          >
            <NotesSidebar
              userId={this.props.userId}
              onNoteClick={this.handleNoteClick}
              onEnlargeClick={this.handleEnlargeClick}
              isMobile={isMobile}
              isMinimized={isMinimized}
            />
            <Sidebar.Pusher
              style={{
                marginRight: isMobile ? (isMinimized ? 60 : 150) : 350,
                backgroundColor: 'white'
              }}
            >
              {!note && (
                <Segment style={{ placeholderStyle }} placeholder basic>
                  <Header icon size="small">
                    <Icon name="sticky note" size="mini" />
                    Моля изберете бележка от менюто вляво
                  </Header>
                </Segment>
              )}
              {note && (
                <Note
                  key={note.id}
                  note={note}
                  isMobile={isMobile}
                  width={width}
                  height={height}
                />
              )}
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Container2>
      </Container>
    );
  }
}

export default Notes;
