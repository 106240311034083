/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type Files_files$ref = any;
type PatientProfile_patient$ref = any;
type Payments_payments$ref = any;
type Threads_threads$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PatientView_viewer$ref: FragmentReference;
export type PatientView_viewer = {|
  +id: string,
  +user: ?{|
    +id: string,
    +threads: ?{|
      +$fragmentRefs: Threads_threads$ref
    |},
    +patientFiles: ?{|
      +$fragmentRefs: Files_files$ref
    |},
    +payments: ?{|
      +$fragmentRefs: Payments_payments$ref
    |},
    +$fragmentRefs: PatientProfile_patient$ref,
  |},
  +$refType: PatientView_viewer$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "PatientView_viewer",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "userId",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "threadFilterBy",
      "type": "ThreadFilterInput",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "filesFilterBy",
      "type": "PatientFilesFilterInput",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "paymentsFilterBy",
      "type": "PaymentFilterInput",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "threadOrderBy",
      "type": "[[String]]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "filesOrderBy",
      "type": "[[String]]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "paymentsOrderBy",
      "type": "[[String]]",
      "defaultValue": null
    }
  ],
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "userId",
          "type": "ID"
        }
      ],
      "concreteType": "User",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "threads",
          "storageKey": null,
          "args": [
            {
              "kind": "Variable",
              "name": "filterBy",
              "variableName": "threadFilterBy",
              "type": "ThreadFilterInput"
            },
            {
              "kind": "Variable",
              "name": "orderBy",
              "variableName": "threadOrderBy",
              "type": "[[String]]"
            }
          ],
          "concreteType": "ThreadConnection",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "Threads_threads",
              "args": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "patientFiles",
          "storageKey": null,
          "args": [
            {
              "kind": "Variable",
              "name": "filterBy",
              "variableName": "filesFilterBy",
              "type": "PatientFilesFilterInput"
            },
            {
              "kind": "Variable",
              "name": "orderBy",
              "variableName": "filesOrderBy",
              "type": "[[String]]"
            }
          ],
          "concreteType": "FileConnection",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "Files_files",
              "args": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "payments",
          "storageKey": null,
          "args": [
            {
              "kind": "Variable",
              "name": "filterBy",
              "variableName": "paymentsFilterBy",
              "type": "PaymentFilterInput"
            },
            {
              "kind": "Variable",
              "name": "orderBy",
              "variableName": "paymentsOrderBy",
              "type": "[[String]]"
            }
          ],
          "concreteType": "PaymentConnection",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "Payments_payments",
              "args": null
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "PatientProfile_patient",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aad49088b27386dd85279cbe6e180399';
module.exports = node;
