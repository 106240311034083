import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "../relay";

const mutation = graphql`
  mutation CreateThreadMutation($input: CreateThreadInput!) {
    createThread(input: $input) {
      thread {
        id
        title
        service {
          id
          name
        }
        createdAt
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export default (payload, success, failure) => {
  const { input, uploadables } = payload;

  commitMutation(environment, {
    mutation,
    variables: {
      input
    },
    uploadables,
    updater: store => {
      // Add to connection
    },
    onCompleted: (response, errors) =>
      errors && errors.length ? failure(errors) : success(response),
    onError: err => failure(err)
  });
};
