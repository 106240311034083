import React, { Component } from 'react';
import { Responsive, Menu } from 'semantic-ui-react';

class DesktopContantainer extends Component {
  state = {};

  render() {
    const { children, menuItems } = this.props;

    return (
      <Responsive minWidth={1031}>
        {menuItems && (
          <Menu
            stackable
            size="huge"
            style={{
              marginLeft: 5,
              marginRight: 5,
              marginBottom: 0,
              height: 72,
            }}
            secondary
          >
            {menuItems}
          </Menu>
        )}
        {children}
      </Responsive>
    );
  }
}

export default DesktopContantainer;
