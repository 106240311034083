import React, { Component } from 'react';
import { Image, Header, Grid, Segment, Icon } from 'semantic-ui-react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import styled from 'styled-components';

const Container = styled.div`
  min-height: calc(100vh - 73px);
`;

const formattedGenders = {
  male: 'Мъж',
  female: 'Жена',
  other: 'Друг'
};

class PatientProfile extends Component {
  render() {
    const { patient } = this.props;
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      dateOfBirth,
      gender
    } = patient;

    const userName = `${firstName} ${lastName}`;
    const formattedGender = gender ? formattedGenders[gender] : '';

    return (
      <Container style={{ ...{ width: this.props.width * 0.995 } }}>
        <Grid style={{ marginLeft: 40, marginTop: 1 }} divided>
          <Grid.Row>
            <Header as="h2">
              <Icon name="id card outline" />
              <Header.Content>
                Профил на пациента
                <Header.Subheader>основна информация</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column width={5} style={{ paddingLeft: 0 }}>
              <Image
                rounded
                src="https://react.semantic-ui.com/images/wireframe/image.png"
                // style={{ width: 250, height: 150 }}
              />
            </Grid.Column>
            <Grid.Column width={11} verticalAlign="middle">
              <Segment>
                <Grid style={{ fontSize: 17 }}>
                  <Grid.Row columns={2}>
                    <Grid.Column
                      width={6}
                      style={{ marginBottom: 12, textAlign: 'right' }}
                    >
                      Име
                    </Grid.Column>
                    <Grid.Column
                      width={10}
                      style={{ marginBottom: 12, textAlign: 'left' }}
                    >
                      {userName}
                    </Grid.Column>
                    <Grid.Column
                      width={6}
                      style={{ marginBottom: 12, textAlign: 'right' }}
                    >
                      Дата на Раждане
                    </Grid.Column>
                    <Grid.Column
                      width={10}
                      style={{ marginBottom: 12, textAlign: 'left' }}
                    >
                      {dateOfBirth}
                    </Grid.Column>
                    <Grid.Column
                      width={6}
                      style={{ marginBottom: 12, textAlign: 'right' }}
                    >
                      Пол
                    </Grid.Column>
                    <Grid.Column
                      width={10}
                      style={{ marginBottom: 12, textAlign: 'left' }}
                    >
                      {formattedGender}
                    </Grid.Column>
                    <Grid.Column
                      width={6}
                      style={{ marginBottom: 12, textAlign: 'right' }}
                    >
                      Телефонен номер
                    </Grid.Column>
                    <Grid.Column
                      width={10}
                      style={{ marginBottom: 12, textAlign: 'left' }}
                    >
                      {phoneNumber}
                    </Grid.Column>
                    <Grid.Column
                      width={6}
                      style={{ marginBottom: 12, textAlign: 'right' }}
                    >
                      Email
                    </Grid.Column>
                    <Grid.Column
                      width={10}
                      style={{ marginBottom: 12, textAlign: 'left' }}
                    >
                      <a href={`mailto:${email}`}>{email}</a>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default createFragmentContainer(
  PatientProfile,
  graphql`
    fragment PatientProfile_patient on User {
      id
      rowId
      firstName
      lastName
      phoneNumber
      createdAt
      dateOfBirth
      gender
      email
    }
  `
);
