import React from "react";
import { Grid, Message, Icon } from "semantic-ui-react";
import styled from "styled-components";

const Container = styled.div`
  height: 100vh;
  flex: 1;
  padding-bottom: 74px;
  // overflow: hidden;
`;

export default class SuccessPayment extends React.Component {
  componentDidMount() {
    const { paymentId } = this.props.match.params;

    setTimeout(
      () => this.props.router.push(`/consultations/create/${paymentId}`),
      2000
    );
  }

  render() {
    return (
      <Container>
        <Grid
          centered
          style={{ height: "100%", overflow: "hidden" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 800 }}>
            <Message icon success size="big">
              <Icon name="circle notched" loading />
              <Message.Content>
                <Message.Header>Успешно плащане</Message.Header>
                Моля изчакайте.
              </Message.Content>
            </Message>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}
