import graphql from "babel-plugin-relay/macro";

const ConsultationsQuery = graphql`
  query ConsultationsQuery_Query {
    viewer {
      ...Consultations_viewer
    }
  }
`;

export default ConsultationsQuery;
