import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "../relay";

const mutation = graphql`
  mutation UpdateResponseMutation($input: UpdateResponseInput!) {
    updateResponse(input: $input) {
      response {
        id
        updatedAt
        text
      }
    }
  }
`;

export default (payload, success, failure) => {
  const { input, uploadables } = payload;
  console.log("inputCommit", input);

  commitMutation(environment, {
    mutation,
    variables: {
      input
    },
    uploadables,
    onCompleted: (response, errors) =>
      errors && errors.length ? failure(errors) : success(response),
    onError: err => failure(err)
  });
};
