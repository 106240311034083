/**
 * @flow
 * @relayHash 0547003bc9dca33285979dabe4dfdc47
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Faq_viewer$ref = any;
export type Faq_QueryVariables = {||};
export type Faq_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: Faq_viewer$ref
  |}
|};
export type Faq_Query = {|
  variables: Faq_QueryVariables,
  response: Faq_QueryResponse,
|};
*/


/*
query Faq_Query {
  viewer {
    ...Faq_viewer
    id
  }
}

fragment Faq_viewer on User {
  organization {
    email
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "Faq_Query",
  "id": null,
  "text": "query Faq_Query {\n  viewer {\n    ...Faq_viewer\n    id\n  }\n}\n\nfragment Faq_viewer on User {\n  organization {\n    email\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "Faq_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Faq_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "Faq_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "Organization",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              },
              v0
            ]
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2eebb25108eff5bed529a7d3df1ec120';
module.exports = node;
