import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { throttle } from 'lodash';
import { createPaginationContainer } from 'react-relay';
import {
  Menu,
  Sidebar,
  Segment,
  Icon,
  Container,
  Header,
  Button
} from 'semantic-ui-react';
import styled from 'styled-components';
import { withRelay } from '../../relay';
import moment from 'moment';
import { CreateNoteMutation, DeleteNoteMutation } from '../../mutations';

const CenteredDiv = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const LIMIT = 3;
const INITIAL_LIMIT = 20;

class NotesSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.throttleScroll = throttle(this.handleScroll, 300);
    this.isSubmitting = false;
    this.tempID = 0;
  }

  handleScroll = e => {
    var nearBottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 300;
    if (!this.props.relay.isLoading() && nearBottom) {
      if (this.props.relay.hasMore()) {
        this.props.relay.loadMore(LIMIT, error => {
          console.log('error', error);
        });
      }
    }
  };
  onScroll = e => {
    e.persist();
    this.throttleScroll(e);
  };

  handleNewNoteClick = () => {
    this.setState({ isSubmitting: true });

    const text = '';
    const title = '';

    const { viewer, userId } = this.props;
    const input = {
      userId,
      text,
      title,
      clientMutationId: String(this.tempID++)
    };

    const connectionName = 'NotesSidebar_patientNotes';
    const filters = { filterBy: { userId: userId } };

    const onSuccess = response => {
      console.log('onSuccess');
      this.props.onNoteClick(response.createNote.noteEdge.node);

      this.setState({ isSubmitting: false });
    };

    const onFailure = e => {
      console.log('onFailure', e);

      this.setState({ isSubmitting: false });
    };

    CreateNoteMutation(
      {
        input,
        connectionName,
        viewer,
        filters
      },
      onSuccess,
      onFailure
    );
  };

  handleDeleteNoteClick = note => {
    this.setState({ isSubmitting: true });

    const { viewer, userId } = this.props;
    const input = {
      id: note.id
    };

    const connectionName = 'NotesSidebar_patientNotes';
    const filters = { filterBy: { userId: userId } };

    const onSuccess = response => {
      console.log('onSuccess');
      this.props.onNoteClick();
      this.setState({
        isSubmitting: false
      });
    };
    const onFailure = () => {
      console.log('onFailure');

      this.setState({ isSubmitting: false });
    };

    DeleteNoteMutation(
      {
        input,
        connectionName,
        viewer,
        filters
      },
      onSuccess,
      onFailure
    );
  };

  renderMenuItem = note => {
    const { title, rowId, updatedAt } = note;
    const { isMobile } = this.props;

    return (
      <Menu.Item
        style={{ borderBottom: '0.5px solid', borderColor: '#DCDCDC' }}
        key={rowId}
        onClick={() => this.props.onNoteClick(note)}
        as="a"
      >
        <Header textAlign="left">
          {title
            ? title.length > 25
              ? title.substring(0, 25) + ' ...'
              : title
            : 'No title'}
        </Header>
        {!isMobile && (
          <Container as="small" textAlign="left">
            {moment(updatedAt).format('LLL')}
          </Container>
        )}
        <div>
          <Icon
            name="trash"
            color="red"
            style={{ position: 'absolute', top: 10, right: 5 }}
            onClick={() => this.handleDeleteNoteClick(note)}
          />
        </div>
      </Menu.Item>
    );
  };

  render() {
    const notes = this.props.viewer.patientNotes.edges.map(({ node }) => node);
    const menuItems = notes.map(v => this.renderMenuItem(v));

    const { isMobile, isMinimized } = this.props;

    return (
      <Sidebar
        onScroll={this.onScroll}
        as={Menu}
        style={{ backgroundColor: 'white', overflow: 'hidden' }}
        icon="labeled"
        onHide={this.handleSidebarHide}
        vertical={true}
        visible={true}
        width={isMobile ? (isMinimized ? 'very thin' : 'thin') : 'wide'}
      >
        {menuItems.length ? (
          isMinimized ? (
            <CenteredDiv onClick={this.props.onEnlargeClick}>
              <Icon
                link
                name="arrow alternate circle right outline"
                size="big"
              />
            </CenteredDiv>
          ) : (
            <Container>
              <Segment style={{ borderStyle: 'none', marginBottom: -20 }}>
                <Button
                  style={{ backgroundColor: 'white' }}
                  icon
                  labelPosition="left"
                  onClick={this.handleNewNoteClick}
                >
                  <Icon
                    style={{ backgroundColor: 'white' }}
                    name="add circle"
                    size="large"
                  />
                  Нова бележка
                </Button>
              </Segment>
              ,{menuItems}
            </Container>
          )
        ) : (
          <Container>
            <Segment style={{ borderStyle: 'none', marginBottom: -20 }}>
              <Button
                style={{ backgroundColor: 'white' }}
                icon
                labelPosition="left"
                onClick={this.handleNewNoteClick}
              >
                <Icon
                  style={{ backgroundColor: 'white' }}
                  name="add circle"
                  size="large"
                />
                Нова бележка
              </Button>
            </Segment>
            <p
              style={{
                position: 'absolute',
                top: 90,
                width: '100%',
                overflow: 'hidden',
                margin: '0 auto',
                paddingLeft: 20,
                paddingRight: 20,
                left: 0,
                right: 0,
                color: 'grey',
                fontSize: 14
              }}
            >
              Нямате създадени бележки
            </p>
          </Container>
        )}
      </Sidebar>
    );
  }
}

const Query = graphql`
  query NotesSidebar_Query(
    $orderBy: [[String]]
    $filterBy: NoteFilterInput
    $after: String
    $count: Int
  ) {
    viewer {
      ...NotesSidebar_viewer
        @arguments(
          orderBy: $orderBy
          filterBy: $filterBy
          after: $after
          count: $count
        )
    }
  }
`;
const NotesSidebarContainer = createPaginationContainer(
  NotesSidebar,
  {
    viewer: graphql`
      fragment NotesSidebar_viewer on User
        @argumentDefinitions(
          orderBy: { type: "[[String]]" }
          filterBy: { type: "NoteFilterInput" }
          after: { type: "String" }
          count: { type: "Int" }
        ) {
        id
        patientNotes(
          filterBy: $filterBy
          orderBy: $orderBy
          after: $after
          first: $count
        ) @connection(key: "NotesSidebar_patientNotes", filters: ["filterBy"]) {
          edges {
            node {
              id
              rowId
              title
              userId
              createdAt
              updatedAt
              text
            }
          }
          pageInfo {
            hasNextPage
            endCursor
            hasPreviousPage
            startCursor
          }
        }
      }
    `
  },
  {
    direction: 'forward',
    query: graphql`
      query NotesSidebarForwardQuery(
        $orderBy: [[String]]
        $filterBy: NoteFilterInput
        $after: String
        $count: Int
      ) {
        viewer {
          ...NotesSidebar_viewer
            @arguments(
              orderBy: $orderBy
              filterBy: $filterBy
              after: $after
              count: $count
            )
        }
      }
    `,
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.patientNotes;
    },
    getFragmentVariables(previousVariables, totalCount) {
      return {
        ...previousVariables,
        count: totalCount
      };
    },
    getVariables(props, paginationInfo, fragmentVariables) {
      return {
        count: paginationInfo.count,
        after: paginationInfo.cursor,
        orderBy: [['updatedAt', 'DESC']],
        filterBy: { userId: props.userId }
      };
    }
  }
);

NotesSidebarContainer.getVariables = props => {
  return {
    orderBy: [['updatedAt', 'DESC']],
    count: INITIAL_LIMIT,
    filterBy: { userId: props.userId }
  };
};

export default withRelay(NotesSidebarContainer, Query);
