import graphql from "babel-plugin-relay/macro";

const CheckoutQuery = graphql`
  query CheckoutQuery_Query {
    viewer {
      ...Checkout_viewer
    }
  }
`;

export default CheckoutQuery;
