/**
 * @flow
 * @relayHash aed5a988663745ca18e67f689ac7141a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Consultation_viewer$ref = any;
export type ResponseFilterInput = {
  threadId?: ?string
};
export type Consultation_QueryVariables = {|
  filterBy?: ?ResponseFilterInput
|};
export type Consultation_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: Consultation_viewer$ref
  |}
|};
export type Consultation_Query = {|
  variables: Consultation_QueryVariables,
  response: Consultation_QueryResponse,
|};
*/


/*
query Consultation_Query(
  $filterBy: ResponseFilterInput
) {
  viewer {
    ...Consultation_viewer_2T86qt
    id
  }
}

fragment Consultation_viewer_2T86qt on User {
  id
  type
  rowId
  responses(filterBy: $filterBy, first: 10000, orderBy: [["createdAt", "ASC"]]) {
    edges {
      node {
        id
        text
        files {
          id
          name
        }
        user {
          rowId
          firstName
          lastName
          type
          id
        }
        createdAt
        updatedAt
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "filterBy",
    "type": "ResponseFilterInput",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "filterBy",
    "variableName": "filterBy",
    "type": "ResponseFilterInput"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000,
    "type": "Int"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": [
      [
        "createdAt",
        "ASC"
      ]
    ],
    "type": "[[String]]"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "Consultation_Query",
  "id": null,
  "text": "query Consultation_Query(\n  $filterBy: ResponseFilterInput\n) {\n  viewer {\n    ...Consultation_viewer_2T86qt\n    id\n  }\n}\n\nfragment Consultation_viewer_2T86qt on User {\n  id\n  type\n  rowId\n  responses(filterBy: $filterBy, first: 10000, orderBy: [[\"createdAt\", \"ASC\"]]) {\n    edges {\n      node {\n        id\n        text\n        files {\n          id\n          name\n        }\n        user {\n          rowId\n          firstName\n          lastName\n          type\n          id\n        }\n        createdAt\n        updatedAt\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "Consultation_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Consultation_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "filterBy",
                "variableName": "filterBy",
                "type": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "Consultation_Query",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v1,
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "responses",
            "storageKey": null,
            "args": v4,
            "concreteType": "ResponseConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ResponseEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Response",
                    "plural": false,
                    "selections": [
                      v1,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "text",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "files",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "File",
                        "plural": true,
                        "selections": [
                          v1,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "user",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          v3,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "lastName",
                            "args": null,
                            "storageKey": null
                          },
                          v2,
                          v1
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updatedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "UFPageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "responses",
            "args": v4,
            "handle": "connection",
            "key": "Consultation_responses",
            "filters": [
              "filterBy"
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5b1be2e617a5bdedfa58c6e439223426';
module.exports = node;
