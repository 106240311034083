import Cookies from 'js-cookie';
import React from 'react';
import { QueryRenderer } from 'react-relay';
import {
  authMiddleware,
  // batchMiddleware,
  errorMiddleware,
  loggerMiddleware,
  perfMiddleware,
  RelayNetworkLayer,
  retryMiddleware,
  urlMiddleware,
} from 'react-relay-network-modern/es';
import { Environment, RecordSource, Store } from 'relay-runtime';

import SidebarPlaceholder from './components/SidebarPlaceholder';

if (process.env.NODE_ENV === 'development') {
  window.SERVER_URL = 'localhost:3001';
} else {
  window.SERVER_URL = process.env.REACT_APP_API_URL;
}

// Environment

const networkMiddlewares = [
  urlMiddleware({
    url: (req) => {
      return Promise.resolve(`//${window.SERVER_URL}/graphql`);
    },
  }),
  // batchMiddleware({
  //   batchUrl: requestMap =>
  //     Promise.resolve(`//${window.SERVER_URL}/graphql/batch`),
  //   batchTimeout: 10
  // }),
  global.__DEV__ ? loggerMiddleware() : null,
  global.__DEV__ ? errorMiddleware() : null,
  global.__DEV__ ? perfMiddleware() : null,
  retryMiddleware({
    fetchTimeout: 10000,
    retryDelays: (attempt) => Math.pow(2, attempt + 4) * 100, // [3200, 6400, 12800, 25600, 51200, 102400, 204800, 409600]
    beforeRetry: ({ forceRetry, abort, delay, attempt, lastError, req }) => {
      window.forceRelayRetry = forceRetry;
      console.log(
        'call `forceRelayRetry()` for immediately retry! Or wait ' +
          delay +
          ' ms.'
      );
    },
    statusCodes: [500, 503, 504],
  }),
  authMiddleware({
    token: () => Cookies.get('OAUTH_TOKEN'),
    // TODO: Refetch token
  }),
  (next) => async (req) => {
    // TODO: Send request ID to identify the request if error occurs

    console.debug('RelayRequest', req);

    const res = await next(req);
    console.debug('RelayResponse', res);

    return res;
  },
];

const networkOptions = {
  noThrow: false
};

const network = new RelayNetworkLayer(networkMiddlewares, networkOptions);
const store = new Store(new RecordSource());

export const environment = new Environment({ network, store });

const getDisplayName = (WrappedComponent) =>
  WrappedComponent.displayName ||
  WrappedComponent.name ||
  (WrappedComponent.render && WrappedComponent.render.displayName) ||
  'Component';

export function withRelay(WrappedComponent, Query) {
  return class RelayWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.displayName = `withRelay(${getDisplayName(WrappedComponent)})`;
    }

    render() {
      return (
        <QueryRenderer
          environment={environment}
          query={Query}
          variables={WrappedComponent.getVariables(this.props)}
          render={({ error, props, retry }) => {
            if (props === null) {
              if (getDisplayName(WrappedComponent).indexOf('Sidebar') !== -1) {
                return <SidebarPlaceholder />;
              }

              return null;
            }

            return (
              <WrappedComponent
                {...this.props}
                {...props}
                retry={retry}
                error={error}
              />
            );
          }}
        />
      );
    }
  };
}

export default {
  environment,
  network,
  store,
};
