import React, { Component } from 'react';
import { Icon, Menu, Button, Sidebar, Grid, Message } from 'semantic-ui-react';
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';
import styled from 'styled-components';

import PatientProfile from './PatientProfile';
import Files from './Files';
import Threads from './Threads';
import Payments from './Payments';
import Notes from './Notes';
import Consultation from '../Consultation';

const Container = styled.div`
  min-height: calc(100vh - 93px);
  flex: 1;
  margin-botton: 72px;
  // overflow: hidden;
`;

class PatientView extends Component {
  state = {
    isVisible: true,
    activeItem: 'profile',
    width: ((window.innerWidth - 350) * 15) / 16,
    isUploading: false
  };

  constructor(props) {
    super(props);
    this.paymentId = null;
    this.thread = null;
    this.refetchVariables = {
      paymentsOrderBy: [['createdAt', 'DESC']],
      filesOrderBy: [['createdAt', 'DESC']],
      threadOrderBy: [['createdAt', 'DESC']]
    };
  }

  handleFileUploadStart = () => this.setState({ isUploading: true });
  handleFileUploadEnd = () => this.setState({ isUploading: false });

  handleIconClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  handleSidebarToggle = e => {
    e.preventDefault();
    if (this.state.isVisible) {
      this.setState({ isVisible: false, width: (window.innerWidth * 15) / 16 });
    } else {
      this.setState({
        isVisible: true,
        width: ((window.innerWidth - 350) * 15) / 16
      });
    }
  };

  handleOrderByClick = () => {
    const { activeItem } = this.state;
    if (activeItem === 'consultations') {
      if (this.refetchVariables.threadOrderBy[0][1] === 'DESC') {
        this.refetchVariables.threadOrderBy = [['createdAt', 'ASC']];
      } else {
        this.refetchVariables.threadOrderBy = [['createdAt', 'DESC']];
      }
    } else if (activeItem === 'files') {
      if (this.refetchVariables.filesOrderBy[0][1] === 'DESC') {
        this.refetchVariables.filesOrderBy = [['createdAt', 'ASC']];
      } else {
        this.refetchVariables.filesOrderBy = [['createdAt', 'DESC']];
      }
    } else if (activeItem === 'payments') {
      if (this.refetchVariables.paymentsOrderBy[0][1] === 'DESC') {
        this.refetchVariables.paymentsOrderBy = [['createdAt', 'ASC']];
      } else {
        this.refetchVariables.paymentsOrderBy = [['createdAt', 'DESC']];
      }
    }
    this.props.relay.refetch(this.refetchVariables);
  };

  handlePaymentClick = ({ node }) => {
    this.paymentId = node.paymentId;
    this.setState({ activeItem: 'payments' });
  };

  handleThreadClick = thread => {
    this.thread = thread;
    this.setState({ activeItem: 'consultation' });
  };

  handleSidebarHide = e => {
    e && e.stopPropagation();
    this.setState({ isVisible: false });
  };

  handleItemClick = (e, { name }) => {
    this.paymentId = null;
    this.setState({ activeItem: name });
  };

  renderContent = (
    isVisible,
    activeItem,
    user,
    threads,
    patientFiles,
    payments,
    userId
  ) => {
    if (activeItem === null) {
      return null;
    }
    if (activeItem === 'profile') {
      return <PatientProfile patient={user} width={this.state.width} />;
      // } else if (activeItem === "appointments") {
      //   console.log("appointments");
    } else if (activeItem === 'notes') {
      return <Notes userId={userId} width={this.state.width} />;
    } else if (activeItem === 'consultations') {
      return (
        <Threads
          threads={threads}
          payments={payments}
          width={this.state.width}
          onClick={this.handleOrderByClick}
          onPaymentClick={this.handlePaymentClick}
          onThreadClick={this.handleThreadClick}
        />
      );
      // } else if (activeItem === "finishedProcedures") {
      //   console.log("finishedProcedures");
    } else if (activeItem === 'files') {
      return (
        <Files
          files={patientFiles}
          width={this.state.width}
          onClick={this.handleOrderByClick}
        />
      );
    } else if (activeItem === 'payments') {
      return (
        <Payments
          payments={payments}
          paymentId={this.paymentId}
          onClick={this.handleOrderByClick}
          width={this.state.width}
        />
      );
    } else if (activeItem === 'consultation') {
      return (
        <Consultation
          thread={this.thread}
          width={window.innerWidth}
          componentWidth={this.state.width}
          onFileUploadStart={this.handleFileUploadStart}
          onFileUploadEnd={this.handleFileUploadEnd}
        />
      );
    }
  };

  renderSidebar = () => {
    const { isVisible, activeItem } = this.state;

    return (
      <Sidebar
        as={Menu}
        direction="left"
        animation="push"
        style={{
          backgroundColor: 'white',
          overflow: 'hidden',
          alignText: 'left'
        }}
        icon="labeled"
        visible={isVisible}
        vertical={true}
        width={'wide'}
      >
        {/* <Menu style={{ alignText: "left" }}> */}
        <Menu.Item
          as="a"
          name="profile"
          active={activeItem === 'profile'}
          onClick={this.handleItemClick}
        >
          <Icon name="id card outline" />
          Профил
        </Menu.Item>
        {/* <Menu.Item
          as="a"
          name="appointments"
          active={activeItem === "appointments"}
          onClick={this.handleItemClick}
        >
          <Icon name="clock outline" />
          Насрочени часове
        </Menu.Item> */}
        <Menu.Item
          as="a"
          name="notes"
          active={activeItem === 'notes'}
          onClick={this.handleItemClick}
        >
          <Icon name="sticky note outline" />
          Бележки
        </Menu.Item>
        <Menu.Item
          as="a"
          name="consultations"
          active={activeItem === 'consultations'}
          onClick={this.handleItemClick}
        >
          <Icon name="user md" />
          Прегледи и консултации
        </Menu.Item>
        {/* <Menu.Item
          as="a"
          name="finishedProcedures"
          active={activeItem === "finishedProcedures"}
          onClick={this.handleItemClick}
        >
          <Icon name="calendar check outline" />
          Извършени процедури
        </Menu.Item> */}
        <Menu.Item
          as="a"
          name="files"
          active={activeItem === 'files'}
          onClick={this.handleItemClick}
        >
          <Icon name="file image outline" />
          Файлове
        </Menu.Item>
        <Menu.Item
          as="a"
          name="payments"
          active={activeItem === 'payments'}
          onClick={this.handleItemClick}
        >
          <Icon name="money bill alternate outline" />
          Плащания
        </Menu.Item>
        {/* </Menu> */}
      </Sidebar>
    );
  };

  render() {
    const { user } = this.props.viewer;

    if (!user) {
      return (
        <Message
          style={{
            marginTop: 30,
            textAlign: 'center'
          }}
          warning
        >
          <Message.Header>Invalid Patient!</Message.Header>
        </Message>
      );
    }
    const { activeItem, isVisible } = this.state;
    const { threads, patientFiles, payments, id } = user;

    return (
      <Container>
        <Sidebar.Pushable
          as={Menu}
          style={{ padding: 0, margin: 0, border: 0 }}
        >
          {this.renderSidebar()}
          <Sidebar.Pusher>
            <Grid>
              <Grid.Column width={1}>
                <Button
                  onClick={this.handleSidebarToggle}
                  animated
                  style={{ backgroundColor: 'transparent', marginTop: 8 }}
                >
                  <Icon name="sidebar" size="big" />
                </Button>
              </Grid.Column>
              <Grid.Column style={{ paddingRight: 40 }} width={15}>
                {this.renderContent(
                  isVisible,
                  activeItem,
                  user,
                  threads,
                  patientFiles,
                  payments,
                  id
                )}
              </Grid.Column>
            </Grid>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Container>
    );
  }
}

const PatientViewContainer = createRefetchContainer(
  PatientView,
  graphql`
    fragment PatientView_viewer on User
      @argumentDefinitions(
        userId: { type: "ID" }
        threadFilterBy: { type: "ThreadFilterInput" }
        filesFilterBy: { type: "PatientFilesFilterInput" }
        paymentsFilterBy: { type: "PaymentFilterInput" }
        threadOrderBy: { type: "[[String]]" }
        filesOrderBy: { type: "[[String]]" }
        paymentsOrderBy: { type: "[[String]]" }
      ) {
      id
      user(id: $userId) {
        id
        threads(filterBy: $threadFilterBy, orderBy: $threadOrderBy) {
          ...Threads_threads
        }
        patientFiles(filterBy: $filesFilterBy, orderBy: $filesOrderBy) {
          ...Files_files
        }
        payments(filterBy: $paymentsFilterBy, orderBy: $paymentsOrderBy) {
          ...Payments_payments
        }
        ...PatientProfile_patient
      }
    }
  `,
  graphql`
    query PatientViewRefetchQuery(
      $userId: ID
      $threadFilterBy: ThreadFilterInput
      $filesFilterBy: PatientFilesFilterInput
      $paymentsFilterBy: PaymentFilterInput
      $threadOrderBy: [[String]]
      $filesOrderBy: [[String]]
      $paymentsOrderBy: [[String]]
    ) {
      viewer {
        ...PatientView_viewer
          @arguments(
            userId: $userId
            threadFilterBy: $threadFilterBy
            filesFilterBy: $filesFilterBy
            paymentsFilterBy: $paymentsFilterBy
            threadOrderBy: $threadOrderBy
            filesOrderBy: $filesOrderBy
            paymentsOrderBy: $paymentsOrderBy
          )
      }
    }
  `
);

PatientViewContainer.getInitialVariables = params => {
  const { userId } = params;
  return {
    userId: userId,
    threadFilterBy: { userId: userId },
    filesFilterBy: { userId: userId },
    paymentsFilterBy: { userId: userId },
    threadOrderBy: [['createdAt', 'DESC']],
    filesOrderBy: [['createdAt', 'DESC']],
    paymentsOrderBy: [['createdAt', 'DESC']]
  };
};

export default PatientViewContainer;
