/**
 * @flow
 * @relayHash c96ecacf8a71f9f5ba668813dc609346
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ConsultationsSidebar_viewer$ref = any;
export type ThreadFilterInput = {
  userId?: ?string,
  isActive?: ?boolean,
};
export type ConsultationsSidebarForwardQueryVariables = {|
  orderBy?: ?$ReadOnlyArray<?$ReadOnlyArray<?string>>,
  filterBy?: ?ThreadFilterInput,
  after?: ?string,
  count?: ?number,
  limit?: ?number,
|};
export type ConsultationsSidebarForwardQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: ConsultationsSidebar_viewer$ref
  |}
|};
export type ConsultationsSidebarForwardQuery = {|
  variables: ConsultationsSidebarForwardQueryVariables,
  response: ConsultationsSidebarForwardQueryResponse,
|};
*/


/*
query ConsultationsSidebarForwardQuery(
  $orderBy: [[String]]
  $filterBy: ThreadFilterInput
  $after: String
  $count: Int
  $limit: Int
) {
  viewer {
    ...ConsultationsSidebar_viewer_2HFv4B
    id
  }
}

fragment ConsultationsSidebar_viewer_2HFv4B on User {
  threads(filterBy: $filterBy, orderBy: $orderBy, after: $after, first: $count, limit: $limit) {
    edges {
      node {
        id
        title
        isActive
        createdAt
        service {
          id
          name
        }
        user {
          id
          firstName
          lastName
          email
          phoneNumber
        }
        hasUnseenResponses
        ...Consultation_thread
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
      hasPreviousPage
      startCursor
    }
  }
}

fragment Consultation_thread on Thread {
  id
  service {
    description
    id
  }
  user {
    id
    rowId
    firstName
    lastName
    email
    phoneNumber
  }
  createdAt
  deactivatedAt
  title
  isActive
  payment {
    id
    rowId
    refId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[[String]]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filterBy",
    "type": "ThreadFilterInput",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "limit",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "filterBy",
    "variableName": "filterBy",
    "type": "ThreadFilterInput"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy",
    "type": "[[String]]"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ConsultationsSidebarForwardQuery",
  "id": null,
  "text": "query ConsultationsSidebarForwardQuery(\n  $orderBy: [[String]]\n  $filterBy: ThreadFilterInput\n  $after: String\n  $count: Int\n  $limit: Int\n) {\n  viewer {\n    ...ConsultationsSidebar_viewer_2HFv4B\n    id\n  }\n}\n\nfragment ConsultationsSidebar_viewer_2HFv4B on User {\n  threads(filterBy: $filterBy, orderBy: $orderBy, after: $after, first: $count, limit: $limit) {\n    edges {\n      node {\n        id\n        title\n        isActive\n        createdAt\n        service {\n          id\n          name\n        }\n        user {\n          id\n          firstName\n          lastName\n          email\n          phoneNumber\n        }\n        hasUnseenResponses\n        ...Consultation_thread\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n      hasPreviousPage\n      startCursor\n    }\n  }\n}\n\nfragment Consultation_thread on Thread {\n  id\n  service {\n    description\n    id\n  }\n  user {\n    id\n    rowId\n    firstName\n    lastName\n    email\n    phoneNumber\n  }\n  createdAt\n  deactivatedAt\n  title\n  isActive\n  payment {\n    id\n    rowId\n    refId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ConsultationsSidebarForwardQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ConsultationsSidebar_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "after",
                "variableName": "after",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "filterBy",
                "variableName": "filterBy",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "limit",
                "variableName": "limit",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "orderBy",
                "variableName": "orderBy",
                "type": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ConsultationsSidebarForwardQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "threads",
            "storageKey": null,
            "args": v1,
            "concreteType": "ThreadConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ThreadEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Thread",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isActive",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "service",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Service",
                        "plural": false,
                        "selections": [
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "description",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "user",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "lastName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "email",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "phoneNumber",
                            "args": null,
                            "storageKey": null
                          },
                          v3
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "hasUnseenResponses",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "deactivatedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "payment",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Payment",
                        "plural": false,
                        "selections": [
                          v2,
                          v3,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "refId",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "UFPageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasPreviousPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "threads",
            "args": v1,
            "handle": "connection",
            "key": "ConsultationsSidebar_threads",
            "filters": [
              "filterBy"
            ]
          },
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd738fb66872fec9f352ebf5e78c3a71a';
module.exports = node;
