import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'found';
import { createRefetchContainer } from 'react-relay';
import styled from 'styled-components';
import PaymentsFilter from './PaymentsFilter';
import PaymentRow from './PaymentRow';
import Pagination from '../Pagination';

const Container = styled.div`
  min-height: calc(100vh - 82px);
  flex: 1;
  padding-bottom: 74px;
  // overflow: hidden;
`;

const refetchVariables = {
  orderBy: [['createdAt', 'DESC']],
  filterBy: { status: null, type: null },
  page: 1,
  first: 10000,
  limit: Pagination.getLimit(),
};

//TODO: optimize payments for mobile
class Payments extends Component {
  handleFilterChange = (filterBy) => {
    refetchVariables.filterBy = filterBy;
    refetchVariables.page = 1;
    this.props.relay.refetch(refetchVariables);
  };

  handlePageSelected = (page) => {
    refetchVariables.page = page;

    this.props.relay.refetch(refetchVariables);
  };

  renderRows = (payments) => {
    // Make sure you undestand how this works
    return payments.map((payment) => (
      <PaymentRow payment={payment} key={payment.id} />
    ));
  };

  render() {
    const { payments } = this.props.viewer;
    const { total, currentPage, start, end } = payments;

    const nodes = this.props.viewer.payments.edges.map((edge) => edge.node);

    return (
      <Container>
        <div style={{ marginLeft: 20, marginRight: 20, marginTop: 10 }}>
          <PaymentsFilter onFilterChange={this.handleFilterChange} />

          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>No.</Table.HeaderCell>
                <Table.HeaderCell>Статут</Table.HeaderCell>
                <Table.HeaderCell>Име пациент</Table.HeaderCell>
                <Table.HeaderCell>Тип плащане </Table.HeaderCell>
                <Table.HeaderCell>Дата</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>{this.renderRows(nodes)}</Table.Body>

            {total > Pagination.getLimit() && (
              <Pagination
                currentPage={currentPage}
                start={start}
                end={end}
                total={total}
                onPageSelected={this.handlePageSelected}
              />
            )}
          </Table>
        </div>
      </Container>
    );
  }
}

const PaymentsContainer = createRefetchContainer(
  withRouter(Payments),
  graphql`
    fragment Payments_viewer on User
      @argumentDefinitions(
        orderBy: { type: "[[String]]" }
        filterBy: { type: "PaymentFilterInput" }
        page: { type: "Int" }
        limit: { type: "Int" }
        first: { type: "Int" }
      ) {
      type
      payments(
        orderBy: $orderBy
        filterBy: $filterBy
        first: 10000
        page: $page
        limit: $limit
      ) @connection(key: "Payment_payments", filters: ["filterBy"]) {
        edges {
          node {
            id
            ...PaymentRow_payment
          }
        }
        currentPage
        total
        start
        end
        pageInfo {
          hasNextPage
        }
      }
    }
  `,
  graphql`
    query PaymentsRefetchQuery(
      $orderBy: [[String]]
      $filterBy: PaymentFilterInput
      $page: Int
      $limit: Int
      $first: Int
    ) {
      viewer {
        ...Payments_viewer
          @arguments(
            orderBy: $orderBy
            filterBy: $filterBy
            page: $page
            first: $first
            limit: $limit
          )
      }
    }
  `
);

PaymentsContainer.getInitialVariables = (params) => ({
  orderBy: [['createdAt', 'DESC']],
  filterBy: { status: null, type: null, paymentId: params.paymentId },
  page: 1,
  first: 10000,
  limit: Pagination.getLimit(),
});

export default PaymentsContainer;
