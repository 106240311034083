import graphql from 'babel-plugin-relay/macro';
import { debounce } from 'lodash';
import React, { Component } from 'react';
import { Table, Input } from 'semantic-ui-react';
import { createRefetchContainer } from 'react-relay';
import styled from 'styled-components';

import PatientRow from './PatientRow';
import Pagination from '../Pagination';

const Container = styled.div`
  min-height: calc(100vh - 72px);
  flex: 1;
  padding-bottom: 74px;
  // overflow: hidden;
`;

const ContentContainer = styled.div`
  padding: 0px 20px 0px 20px;
`;

const refetchVariables = {
  orderBy: [['createdAt', 'DESC']],
  filterBy: { type: 'user', searchWord: null },
  page: 1,
  first: 10000,
  limit: Pagination.getLimit()
};

// TODO: optimize patients for mobile
class Patients extends Component {
  state = {
    isMinimized: false,
    searchWord: '',
    width: null
  };

  constructor(props) {
    super(props);
    this.state.width = window.innerWidth;
    this.delayedSearch = debounce(this.handleSearch, 500);
  }

  handleEnlargeClick = () => {
    this.setState({ isMinimized: false });
  };

  handleSearch = e => {
    const searchWord = e.target.value;

    refetchVariables.filterBy = {
      ...refetchVariables.filterBy,
      searchWord: String(searchWord)
    };

    this.props.relay.refetch(refetchVariables);
  };
  onChange = e => {
    e.persist();
    this.delayedSearch(e);
  };

  handleRowClick = patient => {
    this.props.router.push(`/patients/${patient.id}`);
  };

  handlePageSelected = page => {
    refetchVariables.page = page;

    this.props.relay.refetch(refetchVariables);
  };

  render() {
    const { users = [] } = this.props.viewer;
    const { currentPage, total, start, end } = users;

    return (
      <Container>
        <ContentContainer>
          <Input
            iconPosition="left"
            icon="users"
            style={{ width: '50%', marginTop: 10 }}
            placeholder="Търсене по име"
            onChange={this.onChange}
          />

          <Table celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>No.</Table.HeaderCell>
                <Table.HeaderCell>Име</Table.HeaderCell>
                <Table.HeaderCell>Телефон</Table.HeaderCell>
                <Table.HeaderCell>Имейл</Table.HeaderCell>
                <Table.HeaderCell>Регистриран на</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {users.edges.map(({ node: patient }) => (
                <PatientRow
                  patient={patient}
                  key={patient.id}
                  onRowClick={this.handleRowClick}
                />
              ))}
            </Table.Body>

            {total > Pagination.getLimit() && (
              <Pagination
                currentPage={currentPage}
                start={start}
                end={end}
                total={total}
                onPageSelected={this.handlePageSelected}
              />
            )}
          </Table>
        </ContentContainer>
      </Container>
    );
  }
}

const PatientsContainer = createRefetchContainer(
  Patients,
  graphql`
    fragment Patients_viewer on User
      @argumentDefinitions(
        filterBy: { type: "UsersFilterInput" }
        orderBy: { type: "[[String]]" }
        page: { type: "Int" }
        first: { type: "Int" }
        limit: { type: "Int" }
      ) {
      users(
        filterBy: $filterBy
        orderBy: $orderBy
        page: $page
        first: $first
        limit: $limit
      ) @connection(key: "Patient_users", filters: ["filterBy"]) {
        edges {
          node {
            id
            rowId
            ...PatientRow_patient
          }
        }
        currentPage
        start
        end
        total
      }
    }
  `,
  graphql`
    query PatientsRefetchQuery(
      $filterBy: UsersFilterInput
      $orderBy: [[String]]
      $limit: Int
      $page: Int
      $first: Int
    ) {
      viewer {
        ...Patients_viewer
          @arguments(
            filterBy: $filterBy
            orderBy: $orderBy
            limit: $limit
            page: $page
            first: $first
          )
      }
    }
  `
);

PatientsContainer.getInitialVariables = () => ({
  orderBy: [['createdAt', 'DESC']],
  filterBy: { type: 'user', searchWord: null },
  first: 1000,
  page: 1,
  limit: Pagination.getLimit()
});

export default PatientsContainer;
