import graphql from "babel-plugin-relay/macro";
import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import { createFragmentContainer } from "react-relay";
import moment from "moment";

class PatientRow extends Component {
  state = {};

  render() {
    const { patient } = this.props;
    const {
      rowId,
      firstName,
      lastName,
      phoneNumber,
      email,
      createdAt
    } = patient;
    const userName = `${firstName} ${lastName}`;
    const createdAtFormatted = moment(createdAt).format("DD.MM.YYYY HH:mm");

    return (
      <Table.Row
        onMouseEnter={() => {
          document.body.style.cursor = "pointer";
        }}
        onMouseLeave={() => {
          document.body.style.cursor = "default";
        }}
        onClick={() => this.props.onRowClick(patient)}
      >
        <Table.Cell>{rowId}</Table.Cell>
        <Table.Cell>{userName}</Table.Cell>
        <Table.Cell>{phoneNumber}</Table.Cell>
        <Table.Cell>{email}</Table.Cell>
        <Table.Cell>{createdAtFormatted}</Table.Cell>
      </Table.Row>
    );
  }
}

export default createFragmentContainer(
  PatientRow,
  graphql`
    fragment PatientRow_patient on User {
      id
      rowId
      firstName
      lastName
      phoneNumber
      email
      createdAt
    }
  `
);
