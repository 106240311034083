/**
 * @flow
 * @relayHash ae9519145dfa9e25f0505e7eeb4d2cf5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateNoteInput = {
  clientMutationId?: ?string,
  id: string,
  title?: ?string,
  text?: ?string,
};
export type UpdateNoteMutationVariables = {|
  input: UpdateNoteInput
|};
export type UpdateNoteMutationResponse = {|
  +updateNote: ?{|
    +note: ?{|
      +id: string,
      +title: ?string,
      +updatedAt: ?any,
      +text: ?string,
    |}
  |}
|};
export type UpdateNoteMutation = {|
  variables: UpdateNoteMutationVariables,
  response: UpdateNoteMutationResponse,
|};
*/


/*
mutation UpdateNoteMutation(
  $input: UpdateNoteInput!
) {
  updateNote(input: $input) {
    note {
      id
      title
      updatedAt
      text
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateNoteInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateNote",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateNoteInput!"
      }
    ],
    "concreteType": "UpdateNotePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "note",
        "storageKey": null,
        "args": null,
        "concreteType": "Note",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "text",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateNoteMutation",
  "id": null,
  "text": "mutation UpdateNoteMutation(\n  $input: UpdateNoteInput!\n) {\n  updateNote(input: $input) {\n    note {\n      id\n      title\n      updatedAt\n      text\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateNoteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateNoteMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '95dab1d7cf1099c858dc01fa91eb702d';
module.exports = node;
