import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "../relay";
import SharedUpdater from "../helpers/SharedUpdater";

const mutation = graphql`
  mutation CreateResponseMutation($input: CreateResponseInput!) {
    createResponse(input: $input) {
      clientMutationId
      responseEdge {
        node {
          id
          text
          createdAt
          updatedAt
          user {
            id
            firstName
            lastName
          }
          files {
            id
            name
          }
        }
      }
    }
  }
`;

export default (payload, success, failure) => {
  const { connectionName, input, uploadables, viewer, filters } = payload;

  commitMutation(environment, {
    mutation,
    variables: {
      input
    },
    uploadables,
    updater: store => {
      // Add to connection
      const payload = store.getRootField("createResponse");
      if (!payload) {
        return;
      }

      const newEdge = payload.getLinkedRecord("responseEdge");
      SharedUpdater.addEdgeToConnection(
        store,
        viewer.id,
        newEdge,
        connectionName,
        filters
      );
    },
    onCompleted: (response, errors) =>
      errors && errors.length ? failure(errors) : success(response),
    onError: err => failure(err)
  });
};
