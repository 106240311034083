import PropTypes from 'prop-types';

import {
  Responsive,
  Sidebar,
  Menu,
  Segment,
  Container,
  Icon,
} from 'semantic-ui-react';

import React, { Component } from 'react';
import { withRouter } from 'found';

class Navbar extends Component {
  state = {
    activeItem: window.location.pathname.split('/')[1],
    sidebarOpened: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const activeItem = window.location.pathname.split('/')[1];
    if (prevState.activeItem !== activeItem) {
      this.setState({ activeItem });
    }
  }

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  handleMenuItemClick = (e, { name }) => {
    this.setState({ activeItem: name, sidebarOpened: false });
    this.props.router.push('/' + name);
  };

  render() {
    const { sidebarOpened } = this.state;
    const { children } = this.props;
    const { user } = children[0].props;
    const isAdmin = user && user.type === 'admin';
    const { activeItem } = this.state;

    return (
      <Responsive>
        <Responsive as={Sidebar.Pushable} maxWidth={1031}>
          <Sidebar
            as={Menu}
            animation="push"
            inverted
            // TODO: Handle mobile clicks
            onHide={this.handleSidebarHide}
            vertical={true}
            visible={sidebarOpened}
          >
            <Menu.Item
              key="consultations"
              name="consultations"
              link
              position="right"
              style={{
                backgroundColor:
                  activeItem === 'consultations' ? '#2E2E2F' : '',
              }}
              onClick={this.handleMenuItemClick}
            >
              Kонсултации
            </Menu.Item>
            <Menu.Item
              key="checkout"
              name="checkout"
              link
              position="right"
              style={{
                backgroundColor: activeItem === 'checkout' ? '#2E2E2F' : '',
              }}
              onClick={this.handleMenuItemClick}
            >
              Нова консултация
            </Menu.Item>
            {/* {isAdmin && (
              <>
                <Menu.Item
                  key="payments"
                  name="payments"
                  link
                  position="right"
                  style={{
                    backgroundColor: activeItem === 'payments' ? '#2E2E2F' : '',
                  }}
                  onClick={this.handleMenuItemClick}
                >
                  Плащания
                </Menu.Item>

                <Menu.Item
                  key="patients"
                  name="patients"
                  link
                  position="right"
                  style={{
                    backgroundColor: activeItem === 'patients' ? '#2E2E2F' : '',
                  }}
                  onClick={this.handleMenuItemClick}
                >
                  Пациенти
                </Menu.Item>
              </>
            )} */}
            <Menu.Item
              key="myprofile"
              name="myprofile"
              link
              position="right"
              style={{
                backgroundColor: activeItem === 'myprofile' ? '#2E2E2F' : '',
              }}
              onClick={this.handleMenuItemClick}
            >
              Профил
            </Menu.Item>
            <Menu.Item
              key="logout"
              name="logout"
              link
              position="right"
              onClick={this.handleMenuItemClick}
            >
              <Icon name="sign-out" />
              Изход
            </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher
            dimmed={sidebarOpened}
            onClick={() =>
              sidebarOpened && this.setState({ sidebarOpened: false })
            }
          >
            <Segment
              textAlign="center"
              style={{ padding: '0.2em 0em', height: 72 }}
              vertical={true}
            >
              <Container>
                <Menu inverted secondary size="large" style={{ height: 72 }}>
                  <Menu.Item onClick={this.handleToggle} size="big">
                    <Icon name="sidebar" color="blue" size="big" />
                  </Menu.Item>
                  <Menu.Item
                    size="large"
                    link
                    onClick={() => this.props.router.push('/')}
                  >
                    <img
                      src="/logo-full.png"
                      alt="logo"
                      style={{
                        height: 40,
                        width: 'auto',
                        marginBottom: 8,
                      }}
                    />
                  </Menu.Item>
                </Menu>
              </Container>
            </Segment>
            {children}
          </Sidebar.Pusher>
        </Responsive>
        <Responsive minWidth={1031}>
          <Menu
            secondary
            stackable
            size="huge"
            style={{
              marginLeft: 5,
              marginRight: 5,
              marginBottom: 0,
              height: 72,
            }}
          >
            <Menu.Item
              position="left"
              key="logo"
              name="consultations"
              onClick={this.handleMenuItemClick}
            >
              <img
                style={{
                  width: 200,
                }}
                src={'/logo-full.png'}
                alt="logo"
              />
            </Menu.Item>
            <Menu.Menu key="menu" position="right">
              <Menu.Item
                key="consultations"
                name="consultations"
                link
                position="right"
                style={{
                  borderBottom:
                    activeItem === 'consultations' ? '4px solid #0892d1' : '',
                }}
                onClick={this.handleMenuItemClick}
              >
                Kонсултации
              </Menu.Item>
              <Menu.Item
                key="checkout"
                name="checkout"
                link
                position="right"
                style={{
                  borderBottom:
                    activeItem === 'checkout' ? '4px solid #0892d1' : '',
                }}
                onClick={this.handleMenuItemClick}
              >
                Нова консултация
              </Menu.Item>
              {isAdmin && (
                <Menu.Item
                  key="payments"
                  name="payments"
                  link
                  position="right"
                  style={{
                    borderBottom:
                      activeItem === 'payments' ? '4px solid #0892d1' : '',
                  }}
                  onClick={this.handleMenuItemClick}
                >
                  Плащания
                </Menu.Item>
              )}
              {isAdmin && (
                <Menu.Item
                  key="patients"
                  name="patients"
                  link
                  position="right"
                  style={{
                    borderBottom:
                      activeItem === 'patients' ? '4px solid #0892d1' : '',
                  }}
                  onClick={this.handleMenuItemClick}
                >
                  Пациенти
                </Menu.Item>
              )}
              <Menu.Item
                key="myprofile"
                name="myprofile"
                link
                position="right"
                style={{
                  borderBottom:
                    activeItem === 'myprofile' ? '4px solid #0892d1' : '',
                }}
                onClick={this.handleMenuItemClick}
              >
                Профил
              </Menu.Item>
              <Menu.Item
                key="logout"
                name="logout"
                link
                position="right"
                onClick={this.handleMenuItemClick}
              >
                <Icon name="sign-out" />
                Изход
              </Menu.Item>
            </Menu.Menu>
          </Menu>
          {children}
        </Responsive>
      </Responsive>
    );
  }
}

Navbar.propTypes = {
  children: PropTypes.node,
};

export default withRouter(Navbar);
