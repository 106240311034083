/**
 * @flow
 * @relayHash 1d1c82eefca4bd9da9ca048ba1a6aee7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type App_viewer$ref = any;
export type AppQuery_QueryVariables = {||};
export type AppQuery_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: App_viewer$ref
  |}
|};
export type AppQuery_Query = {|
  variables: AppQuery_QueryVariables,
  response: AppQuery_QueryResponse,
|};
*/


/*
query AppQuery_Query {
  viewer {
    ...App_viewer
    id
  }
}

fragment App_viewer on User {
  type
  id
  rowId
}
*/

const node/*: ConcreteRequest*/ = {
  "kind": "Request",
  "operationKind": "query",
  "name": "AppQuery_Query",
  "id": null,
  "text": "query AppQuery_Query {\n  viewer {\n    ...App_viewer\n    id\n  }\n}\n\nfragment App_viewer on User {\n  type\n  id\n  rowId\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AppQuery_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "App_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AppQuery_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rowId",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
// prettier-ignore
(node/*: any*/).hash = 'c41b05aa821d7597b83e2b6b93f65f43';
module.exports = node;
