/**
 * @flow
 * @relayHash 00ea23bfcce1d00818ac480571caff1f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateThreadInput = {
  clientMutationId?: ?string,
  serviceId: string,
  paymentId: string,
  title?: ?string,
  description?: ?string,
  userId?: ?string,
};
export type CreateThreadMutationVariables = {|
  input: CreateThreadInput
|};
export type CreateThreadMutationResponse = {|
  +createThread: ?{|
    +thread: ?{|
      +id: string,
      +title: ?string,
      +service: ?{|
        +id: string,
        +name: ?string,
      |},
      +createdAt: ?any,
      +user: ?{|
        +id: string,
        +firstName: ?string,
        +lastName: ?string,
      |},
    |}
  |}
|};
export type CreateThreadMutation = {|
  variables: CreateThreadMutationVariables,
  response: CreateThreadMutationResponse,
|};
*/


/*
mutation CreateThreadMutation(
  $input: CreateThreadInput!
) {
  createThread(input: $input) {
    thread {
      id
      title
      service {
        id
        name
      }
      createdAt
      user {
        id
        firstName
        lastName
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateThreadInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createThread",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CreateThreadInput!"
      }
    ],
    "concreteType": "CreateThreadPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "thread",
        "storageKey": null,
        "args": null,
        "concreteType": "Thread",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "service",
            "storageKey": null,
            "args": null,
            "concreteType": "Service",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateThreadMutation",
  "id": null,
  "text": "mutation CreateThreadMutation(\n  $input: CreateThreadInput!\n) {\n  createThread(input: $input) {\n    thread {\n      id\n      title\n      service {\n        id\n        name\n      }\n      createdAt\n      user {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateThreadMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateThreadMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'de86e537f017d28084a85a0e77aaf375';
module.exports = node;
