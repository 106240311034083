/**
 * @flow
 * @relayHash 2ac39b59375798a1162ce07c8237aefa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteResponseInput = {
  id: string,
  clientMutationId?: ?string,
};
export type DeleteResponseMutationVariables = {|
  input: DeleteResponseInput
|};
export type DeleteResponseMutationResponse = {|
  +deleteResponse: ?{|
    +clientMutationId: ?string
  |}
|};
export type DeleteResponseMutation = {|
  variables: DeleteResponseMutationVariables,
  response: DeleteResponseMutationResponse,
|};
*/


/*
mutation DeleteResponseMutation(
  $input: DeleteResponseInput!
) {
  deleteResponse(input: $input) {
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteResponseInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteResponse",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DeleteResponseInput!"
      }
    ],
    "concreteType": "DeleteResponsePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DeleteResponseMutation",
  "id": null,
  "text": "mutation DeleteResponseMutation(\n  $input: DeleteResponseInput!\n) {\n  deleteResponse(input: $input) {\n    clientMutationId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteResponseMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteResponseMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '375e09657f855252fad7a647b8a7729a';
module.exports = node;
