import fetch from 'isomorphic-fetch';
import Cookies from 'js-cookie';

const API = {};

API.call = async (url, options = {}) => {
  const apiUrl = window.SERVER_URL;
  const fullUrl = `//${apiUrl}/${url}`;

  options.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...options.headers
  };

  // HACK: Disable Cache due to issues with Internet Explorer 11
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  if (isIE11) {
    options.headers = {
      ...options.headers,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache'
    };
  }

  const accessToken = Cookies.get('OAUTH_TOKEN') || null;
  if (accessToken) {
    options.headers['authorization'] = 'Bearer ' + accessToken;
  }

  // if (global.io && global.io.id) {
  //   options.headers['socket_id'] = global.io.id;
  // }

  return fetch(fullUrl, options).then(response =>
    response.json().then(json => ({ json, response }))
  );
};

API.delete = (url, options = {}) => {
  options.method = 'DELETE';

  return API.call(url, options);
};

API.get = (url, options = {}) => {
  options.method = 'GET';

  return API.call(url, options);
};

API.post = (url, options = {}) => {
  options.method = 'POST';

  return API.call(url, options);
};

export default API;
