import React, { Component } from 'react';
import styled from 'styled-components';
import graphql from 'babel-plugin-relay/macro';
import {
  Icon,
  Menu,
  Segment,
  Sidebar,
  Header,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { createFragmentContainer } from 'react-relay';
import Consultation from './Consultation';
import ConsultationsSidebar from './ConsultationsSidebar';
import { SetSeenByMutation } from '../mutations';

const Container2 = styled.div`
  height: calc(100vh - 72px);
  overflow: hidden;
`;

const placeholderStyle = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
};

// const renderHeaderMenu = () => {
//   return (
//     <HeaderContainer>
//       <Menu stackable size="huge" style={{ padding: 10, margin: 0 }} secondary>

//       </Menu>
//     </HeaderContainer>
//   );
// };

class Consultations extends Component {
  state = {
    thread: null,
    isUploading: false,
    isMinimized: false,
    width: null,
    selectedFilter: 'all',
    selectedOrder: 'DESC',
  };

  constructor(props) {
    super(props);

    this.state.width = window.innerWidth;
  }

  handleTextAreaFocus = () => {
    const { width } = this.state;
    const isMobile = width && width < 1031;
    if (isMobile) {
      this.setState({ isMinimized: true });
    }
  };

  handleConsultationClick = (thread, selectedFilter, selectedOrder) => {
    const { width } = this.state;
    const isMobile = width && width < 1031;
    const { id } = this.props.viewer;

    this.setState({
      selectedFilter,
      selectedOrder,
      thread,
      isMinimized: isMobile ? true : false,
    });

    const input = {
      threadId: thread.id,
      seenBy: id,
    };

    const onSuccess = () => console.log('Success');

    const onFailure = () => console.log('onFailure');

    SetSeenByMutation({ input }, onSuccess, onFailure);
  };

  handleEnlargeClick = () => {
    this.setState({ isMinimized: false });
  };

  handleFileUploadStart = () => this.setState({ isUploading: true });
  handleFileUploadEnd = () => this.setState({ isUploading: false });

  handleUpdatedWidth = (e, { width }) => {
    if (this.state.isMinimized && width > 1031) {
      return this.setState({ isMinimized: false, width });
    }

    this.setState({ width });
  };

  render() {
    const {
      thread,
      isUploading,
      width,
      isMinimized,
      selectedFilter,
      selectedOrder,
    } = this.state;
    const isMobile = width && width < 1031;

    return (
      <Container2>
        <Sidebar.Pushable
          as={Segment}
          width="thin"
          style={{ margin: 0, padding: 0, borderWidth: 0 }}
        >
          <ConsultationsSidebar
            onClick={this.handleConsultationClick}
            onEnlargeClick={this.handleEnlargeClick}
            isMobile={isMobile}
            isMinimized={isMinimized}
            selectedFilter={selectedFilter}
            selectedOrder={selectedOrder}
          />
          <Sidebar.Pusher
            style={{
              marginRight: isMobile ? (isMinimized ? 60 : 150) : 350,
              backgroundColor: '#f2f5fa',
            }}
          >
            {!thread && (
              <Segment placeholder basic style={placeholderStyle}>
                <Header icon>
                  <Icon name="stethoscope" />
                  Моля изберете консултация от менюто вляво.
                </Header>
              </Segment>
            )}
            {thread && (
              <Consultation
                thread={thread}
                isMobile={isMobile}
                onTextAreaFocus={this.handleTextAreaFocus}
                width={width}
                onFileUploadStart={this.handleFileUploadStart}
                onFileUploadEnd={this.handleFileUploadEnd}
              />
            )}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
        <Dimmer active={isUploading} inverted>
          <Loader size="large">Прикачане на файлове. Моля изчакайте.</Loader>
        </Dimmer>
      </Container2>
    );
  }
}

const ConsultationsContainer = createFragmentContainer(
  Consultations,
  graphql`
    fragment Consultations_viewer on User {
      id
      type
    }
  `
);

export default ConsultationsContainer;
