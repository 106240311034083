/**
 * @flow
 * @relayHash 2d592b02925a78ceeb5917cb83c45892
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MyProfileForm_viewer$ref = any;
export type MyProfileQuery_QueryVariables = {||};
export type MyProfileQuery_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: MyProfileForm_viewer$ref
  |}
|};
export type MyProfileQuery_Query = {|
  variables: MyProfileQuery_QueryVariables,
  response: MyProfileQuery_QueryResponse,
|};
*/


/*
query MyProfileQuery_Query {
  viewer {
    ...MyProfileForm_viewer
    id
  }
}

fragment MyProfileForm_viewer on User {
  id
  type
  firstName
  lastName
  phoneNumber
  email
}
*/

const node/*: ConcreteRequest*/ = {
  "kind": "Request",
  "operationKind": "query",
  "name": "MyProfileQuery_Query",
  "id": null,
  "text": "query MyProfileQuery_Query {\n  viewer {\n    ...MyProfileForm_viewer\n    id\n  }\n}\n\nfragment MyProfileForm_viewer on User {\n  id\n  type\n  firstName\n  lastName\n  phoneNumber\n  email\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MyProfileQuery_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "MyProfileForm_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MyProfileQuery_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "phoneNumber",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
// prettier-ignore
(node/*: any*/).hash = '6898980be74a3613ee574bb1e237439d';
module.exports = node;
