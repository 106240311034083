import graphql from 'babel-plugin-relay/macro';
import React, { Component } from 'react';
import { Table, Icon } from 'semantic-ui-react';
import { createFragmentContainer } from 'react-relay';
import styled from 'styled-components';
import moment from 'moment';

const Container = styled.div`
  min-height: calc(100vh - 93px);
`;

class Payments extends Component {
  render() {
    const { edges } = this.props.payments;
    const { paymentId } = this.props;

    return (
      <Container style={{ ...{ width: this.props.width * 0.995 } }}>
        <Table celled style={{ marginTop: 10 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No.</Table.HeaderCell>
              <Table.HeaderCell>Услуга</Table.HeaderCell>
              <Table.HeaderCell>Сума</Table.HeaderCell>
              <Table.HeaderCell onClick={() => this.props.onClick()}>
                Дата
                <Icon name="sort" />
              </Table.HeaderCell>
              <Table.HeaderCell>Статус</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {edges.map(({ node }) => {
              const selectedPayment = Number(paymentId) === Number(node.rowId);

              return (
                <Table.Row
                  style={{
                    outline: selectedPayment ? '0.5px groove #FFFFFF' : '',
                  }}
                  key={node.rowId}
                >
                  <Table.Cell>{node.rowId}</Table.Cell>
                  <Table.Cell>{node.service && node.service.name}</Table.Cell>
                  <Table.Cell>{node.amount}</Table.Cell>
                  <Table.Cell>
                    {moment(node.createdAt).format('LLL')}
                  </Table.Cell>
                  {node.isSuccessful && (
                    <Table.Cell positive>Успешно</Table.Cell>
                  )}
                  {!node.isSuccessful && (
                    <Table.Cell negative>Неуспешно</Table.Cell>
                  )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

export default createFragmentContainer(
  Payments,
  graphql`
    fragment Payments_payments on PaymentConnection {
      edges {
        node {
          rowId
          amount
          service {
            name
          }
          createdAt
          isSuccessful
        }
      }
    }
  `
);
