/**
 * @flow
 * @relayHash 5bbac4da6444d3b3e464938beddba7b4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateResponseInput = {
  clientMutationId?: ?string,
  threadId: string,
  text?: ?string,
};
export type CreateResponseMutationVariables = {|
  input: CreateResponseInput
|};
export type CreateResponseMutationResponse = {|
  +createResponse: ?{|
    +clientMutationId: ?string,
    +responseEdge: ?{|
      +node: ?{|
        +id: string,
        +text: ?string,
        +createdAt: ?any,
        +updatedAt: ?any,
        +user: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
        |},
        +files: ?$ReadOnlyArray<?{|
          +id: string,
          +name: ?string,
        |}>,
      |}
    |},
  |}
|};
export type CreateResponseMutation = {|
  variables: CreateResponseMutationVariables,
  response: CreateResponseMutationResponse,
|};
*/


/*
mutation CreateResponseMutation(
  $input: CreateResponseInput!
) {
  createResponse(input: $input) {
    clientMutationId
    responseEdge {
      node {
        id
        text
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
        }
        files {
          id
          name
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateResponseInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createResponse",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CreateResponseInput!"
      }
    ],
    "concreteType": "CreateResponsePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "responseEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "ResponseEdge",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Response",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "text",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "updatedAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  v1,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "firstName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastName",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "files",
                "storageKey": null,
                "args": null,
                "concreteType": "File",
                "plural": true,
                "selections": [
                  v1,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateResponseMutation",
  "id": null,
  "text": "mutation CreateResponseMutation(\n  $input: CreateResponseInput!\n) {\n  createResponse(input: $input) {\n    clientMutationId\n    responseEdge {\n      node {\n        id\n        text\n        createdAt\n        updatedAt\n        user {\n          id\n          firstName\n          lastName\n        }\n        files {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateResponseMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateResponseMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '265f0aeb0b495fc6460c30d3c6718838';
module.exports = node;
