import React, { Component } from 'react';
import { Select, Button, Icon, Segment, Grid, Header } from 'semantic-ui-react';

const typeOptions = [
  { text: 'Всички', value: 'all' },
  { text: 'POS', value: 'POS' },
  { text: 'Online', value: 'Online' }
];

const statusOptions = [
  { text: 'Всички', value: 'all' },
  { text: 'Успешна', value: '00' },
  { text: 'Неуспешна', value: '01' }
];

class PaymentsFilter extends Component {
  state = {
    status: 'all',
    type: 'all',
    dateRange: ''
  };

  handleChange = (event, { value, name }) => {
    this.setState({ [name]: value });
  };

  handleFilterButtonClick = (event, data) => {
    let { status, type } = this.state;

    if (status === 'all') {
      status = null;
    }

    if (type === 'all') {
      type = null;
    }

    this.props.onFilterChange({ status, type });
  };

  render() {
    const { status, type } = this.state;

    return (
      <Segment>
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column>
              <Header size="small">Тип плащане</Header>
              <Select
                fluid
                onChange={this.handleChange}
                name="type"
                options={typeOptions}
                value={type}
                placeholder="Избери тип плащане"
              />
            </Grid.Column>
            <Grid.Column>
              <Header size="small">Статут на плащането</Header>
              <Select
                fluid
                onChange={this.handleChange}
                name="status"
                options={statusOptions}
                value={status}
                placeholder="Избери статус"
              />
            </Grid.Column>

            {/* <Form.Select
            fluid
            label="Дата"
            options={dateOptions}
            value={dateRange}
            placeholder="От-до "
          /> */}

            <Button
              color="blue"
              style={{ marginTop: 30 }}
              icon
              labelPosition="left"
              onClick={this.handleFilterButtonClick}
            >
              <Icon name="filter" />
              Филтър
            </Button>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
export default PaymentsFilter;
