import React from "react";
import { Grid, Message, Icon, Button } from "semantic-ui-react";
import styled from "styled-components";

const Container = styled.div`
  height: 100vh;
  flex: 1;
  padding-bottom: 74px;
  // overflow: hidden;
`;

export default class ErrorPayment extends React.Component {
  render() {
    return (
      <Container>
        <Grid
          centered
          style={{ height: "100%", overflow: "hidden" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 800 }}>
            <Message icon error size="big">
              <Icon name="warning circle" />
              <Message.Content>
                <Message.Header>Възникна грешка</Message.Header>
                Неуспешно плащане. Моля опитайте отново!
              </Message.Content>
            </Message>
            <Button
              icon
              labelPosition="left"
              onClick={() => this.props.router.push("/checkout")}
            >
              <Icon name="left arrow" />
              Назад
            </Button>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}
