/**
 * @flow
 * @relayHash d67f9fb03a0a83da8f5222e716b2d570
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Checkout_viewer$ref = any;
export type CheckoutQuery_QueryVariables = {||};
export type CheckoutQuery_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: Checkout_viewer$ref
  |}
|};
export type CheckoutQuery_Query = {|
  variables: CheckoutQuery_QueryVariables,
  response: CheckoutQuery_QueryResponse,
|};
*/


/*
query CheckoutQuery_Query {
  viewer {
    ...Checkout_viewer
    id
  }
}

fragment Checkout_viewer on User {
  id
  type
  services {
    id
    name
    description
    amount
    imageUrl
  }
  unprocessedPayments {
    id
    rowId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "CheckoutQuery_Query",
  "id": null,
  "text": "query CheckoutQuery_Query {\n  viewer {\n    ...Checkout_viewer\n    id\n  }\n}\n\nfragment Checkout_viewer on User {\n  id\n  type\n  services {\n    id\n    name\n    description\n    amount\n    imageUrl\n  }\n  unprocessedPayments {\n    id\n    rowId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CheckoutQuery_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Checkout_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CheckoutQuery_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "services",
            "storageKey": null,
            "args": null,
            "concreteType": "Service",
            "plural": true,
            "selections": [
              v0,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "amount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "imageUrl",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "unprocessedPayments",
            "storageKey": null,
            "args": null,
            "concreteType": "Payment",
            "plural": true,
            "selections": [
              v0,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "rowId",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c57094eab2045158ab4bb099a58c7c2e';
module.exports = node;
