import graphql from "babel-plugin-relay/macro";

const PaymentsQuery = graphql`
  query PaymentsQuery_Query(
    $orderBy: [[String]]
    $filterBy: PaymentFilterInput
    $page: Int
    $limit: Int
    $first: Int
  ) {
    viewer {
      type
      ...Payments_viewer
        @arguments(
          orderBy: $orderBy
          filterBy: $filterBy
          page: $page
          first: $first
          limit: $limit
        )
    }
  }
`;

export default PaymentsQuery;
