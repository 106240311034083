import "@babel/polyfill";
import "react-app-polyfill/ie9";

import createFarceRouter from "found/lib/createFarceRouter";
import createRender from "found/lib/createRender";
import queryMiddleware from "farce/lib/queryMiddleware";
import BrowserProtocol from "farce/lib/BrowserProtocol";
import { Resolver } from "found-relay";
import React from "react";
import ReactDOM from "react-dom";

// import registerServiceWorker from './registerServiceWorker';

import { environment } from "./relay";
import routes from "./routes";
import "./config";

const FullPageError = props => (
  <div>{props.error.status === 404 ? "Not found" : "Error"}</div>
);

const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware],
  routeConfig: routes,
  render: createRender({
    renderError: ({ error }) => <FullPageError error={error} />
  })
});

ReactDOM.render(
  <Router resolver={new Resolver(environment)} />,
  document.getElementById("root")
);

// registerServiceWorker();
