import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'found';
import React, { Component } from 'react';
import { createFragmentContainer } from 'react-relay';
import styled from 'styled-components';
import {
  Grid,
  Form,
  Button,
  Image,
  Dimmer,
  Loader,
  Segment,
} from 'semantic-ui-react';
import FileUploadButton from './FileUploadButton';
import { CreateThreadMutation } from '../mutations';

const Container = styled.div`
  min-height: 100vh;
  flex: 1;
  padding-bottom: 74px;
  overflow: hidden;
`;

class ConsultationForm extends Component {
  state = {
    title: '',
    description: '',
    isSubmitting: false,
    isUploading: false,
    files: [],
  };

  constructor(props) {
    super(props);

    this.state.width = window.innerWidth;
  }

  handleSendButtonClick = () => {
    const { title, description, files } = this.state;

    if (files.length > 0) {
      this.setState({ isUploading: true });
    } else {
      this.setState({ isSubmitting: true });
    }

    const { payment } = this.props.viewer;
    const { service } = payment;

    const input = {
      title,
      description,
      serviceId: service.id,
      paymentId: payment.id,
      userId: payment.userId,
    };
    console.log('input', input);

    const onSuccess = () => {
      console.log('Success');
      this.props.router.push('/consultations');
    };

    const onFailure = () => {
      console.log('Failure');
      this.setState({
        isEmailModalShown: false,
        isSubmitting: false,
        isUploading: false,
      });
    };

    CreateThreadMutation({ input, uploadables: files }, onSuccess, onFailure);
  };

  handleEmailSubmitClick = () => {
    this.createThread(this.state.email);
  };

  handleUploadButton = (newFiles) => {
    const { files } = this.state;
    this.setState({ files: [...files, ...newFiles] });
  };

  handleUnattachFiles = () => {
    this.setState({ files: [] });
  };

  handleUpdatedWidth = (e, { width }) => this.setState({ width });

  createThread = (userEmail = null) => {};

  render() {
    const { viewer } = this.props;
    const { payment, rowId } = viewer;

    if (!payment) {
      this.props.router.push('/');
      return null;
    }

    if (rowId !== payment.userId && viewer.type !== 'admin') {
      this.props.router.push('/');
      return null;
    }

    const { service } = payment;

    const {
      title,
      description,
      files,
      isSubmitting,
      isUploading,
      width,
    } = this.state;

    const promptLines = service.longPrompt.split('<br />');

    const isMobile = width && width < 1031;
    const smallButtons = width < 570;

    const promptText = promptLines.map((line) => (
      <p
        style={{
          textAlign: 'left',
          color: 'black',
        }}
      >
        {line}
      </p>
    ));

    return (
      <Container>
        {isMobile && (
          <div style={{ padding: isMobile && 30 }}>
            {promptText}
            {service.rowId === 1 && (
              <div>
                <em>Образец:</em>
                <Segment>
                  <Grid>
                    <Grid.Row columns={7}>
                      <Grid.Column>
                        <Image src="/faceview1.png" />
                      </Grid.Column>
                      <Grid.Column>
                        <Image src="/faceview2.png" />
                      </Grid.Column>
                      <Grid.Column>
                        <Image src="/faceview3.png" />
                      </Grid.Column>
                      <Grid.Column>
                        <Image src="/faceview4.png" />
                      </Grid.Column>
                      <Grid.Column>
                        <Image src="/faceview5.png" />
                      </Grid.Column>
                      <Grid.Column>
                        <Image src="/faceview6.png" />
                      </Grid.Column>
                      <Grid.Column>
                        <Image src="/faceview7.png" />
                      </Grid.Column>

                      {/* {service.rowId === 1 && (
                <p style={{ paddingTop: 20 }}>
                  <Image src="/rhyno-sample.jpg" />
                </p>
              )} */}
                    </Grid.Row>
                  </Grid>
                </Segment>
              </div>
            )}
          </div>
        )}
        <Grid
          style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 30 }}
          divided
        >
          {!isMobile && (
            <Grid.Column width={6}>
              <Grid.Row style={{ marginTop: -10 }}>
                {promptText}
                {service.rowId === 1 && (
                  <div>
                    <em style={{ paddingTop: 20 }}>
                      Образец:
                      {/* <Image src="/rhyno-sample.jpg" /> */}
                    </em>
                    <Grid style={{ paddingTop: 10 }}>
                      <Grid.Row columns={3}>
                        <Grid.Column>
                          <Segment>
                            <Image
                              style={{ paddingBottom: 10 }}
                              src="/faceview1.png"
                            />
                            <Image
                              style={{ paddingBottom: 10 }}
                              src="/faceview2.png"
                            />
                            <Image src="/faceview3.png" />
                          </Segment>
                        </Grid.Column>
                        <Grid.Column>
                          <Segment>
                            <Image
                              style={{ paddingBottom: 10 }}
                              src="/faceview4.png"
                            />
                            <Image src="/faceview5.png" />
                          </Segment>
                        </Grid.Column>
                        <Grid.Column>
                          <Segment>
                            <Image
                              style={{ paddingBottom: 10 }}
                              src="/faceview6.png"
                            />
                            <Image src="/faceview7.png" />
                          </Segment>
                        </Grid.Column>

                        {/* {service.rowId === 1 && (
                    <p style={{ paddingTop: 20 }}>
                      <Image src="/rhyno-sample.jpg" />
                    </p>
                  )} */}
                      </Grid.Row>
                    </Grid>
                  </div>
                )}
              </Grid.Row>
            </Grid.Column>
          )}
          <Grid.Column width={!isMobile && 10}>
            <Form>
              <Form.Input
                required
                id="form-input-control-last-name"
                label="Проблем"
                placeholder="Кратко описание"
                onChange={(e, { value }) => this.setState({ title: value })}
              />
              <Form.TextArea
                style={{
                  height: 'calc(100vh - 285px)',
                  fallbacks: [
                    { height: '-moz-calc(100vh - 285px)' },
                    { height: '-webkit-calc(100vh - 285px)' },
                    { height: '-o-calc(100vh - 285px)' },
                  ],
                }}
                // control={TextArea}
                onChange={(e, { value }) =>
                  this.setState({ description: value })
                }
                required
                label="Описание"
                placeholder="Моля подробно опишете проблема си следвайки указанията"
              />
            </Form>
            <Grid style={{ paddingTop: 30, paddingBottom: isMobile ? 20 : 0 }}>
              <Grid.Column width={6}>
                <Button.Group>
                  <FileUploadButton
                    content={
                      smallButtons
                        ? null
                        : files.length
                        ? `Прикачи файлове (${files.length})`
                        : 'Прикачи файлове'
                    }
                    labelPosition={!smallButtons && 'left'}
                    icon="upload"
                    disabled={isSubmitting}
                    onSelect={this.handleUploadButton}
                  />
                  {files.length > 0 && (
                    <Button icon="close" onClick={this.handleUnattachFiles} />
                  )}
                </Button.Group>
              </Grid.Column>
              <Grid.Column width={10}>
                <Button
                  style={{ width: '100%' }}
                  content="Изпрати"
                  labelPosition="left"
                  icon="edit"
                  primary
                  loading={isSubmitting}
                  disabled={title.length === 0 || description.length === 0}
                  onClick={this.handleSendButtonClick}
                />
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
        <Dimmer active={isUploading} inverted>
          <Loader size="large">
            Прикачане на файлове. Моля изчакайте.
            <br /> Не презареждайте и не затваряйте страницата, докато файловете
            не се качат.
          </Loader>
        </Dimmer>
      </Container>
    );
  }
}

export default createFragmentContainer(
  withRouter(ConsultationForm),
  graphql`
    fragment ConsultationForm_viewer on User {
      id
      rowId
      type
      payment(id: $paymentId) {
        id
        rowId
        userId
        service {
          id
          rowId
          shortPrompt
          longPrompt
        }
      }
    }
  `
);
