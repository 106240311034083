/**
 * @flow
 * @relayHash d14b7bcab7ea6280aa4ca2af5ea8424c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteNoteInput = {
  id: string,
  clientMutationId?: ?string,
};
export type DeleteNoteMutationVariables = {|
  input: DeleteNoteInput
|};
export type DeleteNoteMutationResponse = {|
  +deleteNote: ?{|
    +clientMutationId: ?string
  |}
|};
export type DeleteNoteMutation = {|
  variables: DeleteNoteMutationVariables,
  response: DeleteNoteMutationResponse,
|};
*/


/*
mutation DeleteNoteMutation(
  $input: DeleteNoteInput!
) {
  deleteNote(input: $input) {
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteNoteInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteNote",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DeleteNoteInput!"
      }
    ],
    "concreteType": "DeleteNotePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DeleteNoteMutation",
  "id": null,
  "text": "mutation DeleteNoteMutation(\n  $input: DeleteNoteInput!\n) {\n  deleteNote(input: $input) {\n    clientMutationId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteNoteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteNoteMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd0556d5153ad48e9dd356af761b0111a';
module.exports = node;
