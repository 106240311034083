/**
 * @flow
 * @relayHash 06116da0e20215d23ae8cfce3e82951e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ConsultationForm_viewer$ref = any;
export type ConsultationFormQuery_QueryVariables = {|
  paymentId?: ?string
|};
export type ConsultationFormQuery_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: ConsultationForm_viewer$ref
  |}
|};
export type ConsultationFormQuery_Query = {|
  variables: ConsultationFormQuery_QueryVariables,
  response: ConsultationFormQuery_QueryResponse,
|};
*/


/*
query ConsultationFormQuery_Query(
  $paymentId: String
) {
  viewer {
    ...ConsultationForm_viewer
    id
  }
}

fragment ConsultationForm_viewer on User {
  id
  rowId
  type
  payment(id: $paymentId) {
    id
    rowId
    userId
    service {
      id
      rowId
      shortPrompt
      longPrompt
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "paymentId",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ConsultationFormQuery_Query",
  "id": null,
  "text": "query ConsultationFormQuery_Query(\n  $paymentId: String\n) {\n  viewer {\n    ...ConsultationForm_viewer\n    id\n  }\n}\n\nfragment ConsultationForm_viewer on User {\n  id\n  rowId\n  type\n  payment(id: $paymentId) {\n    id\n    rowId\n    userId\n    service {\n      id\n      rowId\n      shortPrompt\n      longPrompt\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ConsultationFormQuery_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ConsultationForm_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ConsultationFormQuery_Query",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v1,
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "payment",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "paymentId",
                "type": "String"
              }
            ],
            "concreteType": "Payment",
            "plural": false,
            "selections": [
              v1,
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "userId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "service",
                "storageKey": null,
                "args": null,
                "concreteType": "Service",
                "plural": false,
                "selections": [
                  v1,
                  v2,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shortPrompt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "longPrompt",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ad419b70891e7ad69658c5d7d3aa8db8';
module.exports = node;
