import PropTypes from "prop-types";
import React from "react";
import DesktopContainer from "./DesktopContainer";
import MobileContainer from "./MobileContainer";

const ResponsiveContainer = ({ children, menuItems, onUpdate }) => (
  <div>
    <DesktopContainer onUpdate={onUpdate} menuItems={menuItems}>
      {children}
    </DesktopContainer>
    <MobileContainer onUpdate={onUpdate} menuItems={menuItems}>
      {children}
    </MobileContainer>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node
};

export default ResponsiveContainer;
