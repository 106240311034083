import React from "react";
import ReactCookieBanner from "react-cookie-banner";

const message =
  "Този сайт използва бисквитки, за да подобри Вашето преживявване. Чрез посещението си, вие приемате използването на бисквитки и общите условия на сайта.";

export default props => (
  <ReactCookieBanner
    styles={styles}
    message={message}
    link={
      <a href="https://sinumed.net/%D0%BF%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0-%D0%B7%D0%B0-%D0%BF%D0%BE%D0%B2%D0%B5%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D0%BD%D0%BE%D1%81%D1%82/">
        Повече информация тук
      </a>
    }
    buttonMessage="Затвори"
    dismissOnScroll={false}
    dismissOnClick={true}
  />
);

const styles = {
  banner: {
    fontFamily: "Source Sans Pro",
    // minHeight: 70,
    background: "rgba(52, 64, 81, 0.88) url(/cookie.png) 20px 50% no-repeat",
    backgroundSize: "30px 30px",
    fontSize: "15px",
    fontWeight: 600,
    height: "auto",
    position: "absolute",
    bottom: 0
  },
  button: {
    border: "1px solid white",
    borderRadius: 4,
    width: 74,
    // height: 32,
    lineHeight: "32px",
    background: "transparent",
    color: "white",
    fontSize: "14px",
    fontWeight: 600,
    opacity: 1,
    right: 20,
    marginTop: -16
  },
  message: {
    padding: "9px 30px",
    width: "calc(100vw - 100px)",
    display: "block",
    lineHeight: 1.3,
    textAlign: "left",
    color: "white"
  },
  link: {
    textDecoration: "none",
    fontWeight: "bold"
  }
};
