import React from 'react';
import {
  Grid,
  Accordion,
  Menu,
  Message,
  Icon,
  Button,
  List,
} from 'semantic-ui-react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';

import ResponsiveContainer from './ResponsiveContainer';
import { withRelay } from '../relay';

class Faq extends React.Component {
  state = {
    activeIndex: null,
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  menuItems = [
    <Menu.Item size="large" link href={'/'}>
      <img src="/logo-full.png" alt="logo" style={{ width: 245 }} />
    </Menu.Item>,
    <Menu.Menu position="right">
      <Menu.Item name="login" link position="right" href={'/'}>
        Вход
      </Menu.Item>
      <Menu.Item
        name="signUp"
        position="right"
        link
        href={'/signup'}
        color="blue"
        style={{ color: 'white' }}
      >
        <Button style={{ backgroundColor: '#2185d0', color: 'white' }}>
          Регистрация
        </Button>
      </Menu.Item>
    </Menu.Menu>,
  ];

  render() {
    const { activeIndex } = this.state;
    const { email } = this.props.viewer.organization;

    return (
      <ResponsiveContainer
        style={{
          flex: 1,
          minHeight: '100vh',
          overflow: 'hidden',
        }}
        menuItems={this.menuItems}
      >
        <Grid
          centered
          style={{ minHeight: 'calc(100vh - 72px)', overflow: 'hidden' }}
        >
          <Grid.Column style={{ maxWidth: '80%', marginTop: 50 }}>
            <Accordion styled fluid>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                Как протича една онлайн консултация?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                Регистрирате се с Вашия имейл и парола по избор. Заплащате
                таксата и избирате естеството на Вашия проблем:
                <strong> функционален</strong> или <strong> естетически</strong>
                . След това описвате Вашите оплаквания в полето за консултация.
                В зависимост от вида консултация е необходимо да приложите:
                <br />
                <List bulleted>
                  <List.Item>
                    При функционален проблем – образно изследване (скенер на нос
                    и околоносни кухини).
                  </List.Item>

                  <List.Item>
                    При естетически проблем - снимки на Вашия нос по дадения
                    образец.
                  </List.Item>
                </List>
                <br />В рамките на 10 работни дни, от започването на
                консултацията, ще получите отговор. Ще Ви бъде изпратен имейл,
                който ще Ви съобщи, че на платформата е качен отговор на Вашето
                запитване.
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                Какво е необходимо да приложа към описанието на проблема?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 1}>
                <p>
                  В зависимост от естеството на Вашия проблем е необходимо да
                  приложите:
                  <br />
                  <List bulleted>
                    <List.Item>
                      При функционален проблем –
                      <strong>
                        образно изследване (скенер на нос и околоносни кухини).
                      </strong>
                    </List.Item>
                    <List.Item>
                      При естетически проблем –
                      <strong> снимки на Вашия нос по дадения образец.</strong>
                    </List.Item>
                  </List>
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                Как да прикача образно изследване?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 2}>
                <p>
                  За да успеете да прикачите образното изследване, моля,
                  следвайте тези стъпки:
                  <br />
                  <List ordered>
                    <List.Item>
                      Свалете образното изследване от диска в папка на компютъра
                      (всички налични файлове).
                    </List.Item>
                    <List.Item>
                      След като вече е свален, архивирайте папката с WinZip,
                      WinRAR или друга архивираща програма.
                    </List.Item>
                    <List.Item>
                      Прикачете архивирания файл на платформата.
                    </List.Item>
                  </List>
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 3}
                index={3}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                Желая корекция на носа. Какви снимки е необходимо да приложа?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 3}>
                <p>
                  За извършване на първоначална консултация относно естетическа
                  корекция на носа е необходимо до предоставите седем снимки в
                  следните позиции: анфас, ляв и десен профил, ляв и десен
                  полупрофил, позиция с наведена надолу глава и позиция с
                  вдигната нагоре глава. /образец снимки/ Снимките трябва да са
                  направени на разстояние от 1-1,5 м, при естествена светлина,
                  която равномерно да осветява цялото лице. Фонът трябва да е
                  неутрален, едноцветен.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 4}
                index={4}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                Не успявам да прикача файлове на платформата.
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 4}>
                <p>
                  В случай че възникне проблем при регистрацията и/или
                  прикачването на образното изследване, моля, пишете ни на
                  <a href={`mailto:${email}`}> {email}</a>
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 5}
                index={5}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                Кога да очаквам отговор?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 5}>
                <p>
                  Ще получите отговор до 10 работни дни от започването на
                  консултацията.
                </p>
              </Accordion.Content>
            </Accordion>

            <Message>
              <Icon size="large" name="help" />
              При всякакви други въпроси, моля, пишете ни на
              <a href={`mailto:${email}`}> {email}</a>
            </Message>
          </Grid.Column>
        </Grid>
      </ResponsiveContainer>
    );
  }
}

const Query = graphql`
  query Faq_Query {
    viewer {
      ...Faq_viewer
    }
  }
`;

const FaqContainer = createFragmentContainer(
  Faq,
  graphql`
    fragment Faq_viewer on User {
      organization {
        email
      }
    }
  `
);

FaqContainer.getVariables = (props) => {
  return null;
};

export default withRelay(FaqContainer, Query);
