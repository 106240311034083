/**
 * @flow
 * @relayHash 3412470bc9f2107d345ca4cb105471bd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Consultations_viewer$ref = any;
export type ConsultationsQuery_QueryVariables = {||};
export type ConsultationsQuery_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: Consultations_viewer$ref
  |}
|};
export type ConsultationsQuery_Query = {|
  variables: ConsultationsQuery_QueryVariables,
  response: ConsultationsQuery_QueryResponse,
|};
*/


/*
query ConsultationsQuery_Query {
  viewer {
    ...Consultations_viewer
    id
  }
}

fragment Consultations_viewer on User {
  id
  type
}
*/

const node/*: ConcreteRequest*/ = {
  "kind": "Request",
  "operationKind": "query",
  "name": "ConsultationsQuery_Query",
  "id": null,
  "text": "query ConsultationsQuery_Query {\n  viewer {\n    ...Consultations_viewer\n    id\n  }\n}\n\nfragment Consultations_viewer on User {\n  id\n  type\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ConsultationsQuery_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Consultations_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ConsultationsQuery_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
// prettier-ignore
(node/*: any*/).hash = '5bf4e73321207b753a7b7d785b17a855';
module.exports = node;
