import graphql from 'babel-plugin-relay/macro';

const AppQuery = graphql`
  query AppQuery_Query {
    viewer {
      ...App_viewer
    }
  }
`;

export default AppQuery;
