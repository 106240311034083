import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "../relay";

const mutation = graphql`
  mutation CreatePaymentMutation($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      payment {
        id
      }
    }
  }
`;

export default (input, success, failure) => {
  commitMutation(environment, {
    mutation,
    variables: {
      input
    },
    onCompleted: (response, errors) =>
      errors && errors.length ? failure(errors) : success(response),
    onError: err => failure(err)
  });
};
