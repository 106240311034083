import * as React from 'react';
import { Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import * as uuid from 'uuid';

export class FileButton extends Component {
  constructor(props) {
    super(props);

    this.id = uuid.v1();
    this.onChangeFile = this.onChangeFile.bind(this);
  }

  render() {
    return (
      <Button
        icon
        labelPosition={this.props.labelPosition}
        disabled={this.props.disabled}
        as="label"
        htmlFor={this.id}
      >
        <Icon name={this.props.icon} />
        {this.props.content}
        <input
          hidden
          id={this.id}
          multiple
          type="file"
          onChange={this.onChangeFile}
        />
      </Button>
    );
  }

  onChangeFile() {
    const fileButton = document.getElementById(this.id);
    // console.log("fileButton.files", fileButton.files);
    const files = fileButton ? fileButton.files : [];
    if (this.props.onSelect) {
      this.props.onSelect(files);
    }
  }
}

export default FileButton;
