/* eslint-disable jsx-a11y/anchor-is-valid */
import Cookies from 'js-cookie';
import React, { Component } from 'react';
import styled from 'styled-components';
import graphql from 'babel-plugin-relay/macro';
import {
  Button,
  Form,
  Grid,
  Header,
  Modal,
  Menu,
  Message,
  Segment,
  Input,
} from 'semantic-ui-react';
import { createFragmentContainer } from 'react-relay';

import { getCookieDomain } from '../helpers';
import API from '../helpers/api';
import CookieBanner from './CookieBanner';
import ResponsiveContainer from './ResponsiveContainer';

// const NavBar = styled.div`
//   right: 0;
//   left: 0;
//   height: 80px;
//   background-color: #41b3ff;
//   justify-content: center;
//   align-items: center;
//   flex: 1;
// `;

const FullPageImage = styled.div`
  /* The image used */
  background-image: url(${(props) =>  props.imageUrl});

  /* Full height */
  max-height: calc(100vh - 72px);
  margin-top: 72px;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  z-index: -1;
`;

class Login extends Component {
  state = {
    email: '',
    password: '',
    error: null,
    isLoading: false,
    isPasswordModalShown: false,
    isMobile: false,
    forgottenPasswordError: null,
  };

  constructor(props) {
    super(props);
    this.state.width = window.innerWidth;
  }

  menuItems = [
    <Menu.Item key="logo" size="large" link>
      <img src="/logo-full.png" alt="logo" style={{ width: 245 }} />
    </Menu.Item>,
    <Menu.Menu key="rightMenu" position="right">
      {/* <Menu.Item
        name="features"
        link
        position="right"
        onClick={this.handleItemClick}
      >
        Как протича онлайн консултацията?
      </Menu.Item> */}
      <Menu.Item
        name="faq"
        link
        position="right"
        href={'/faq'}
        onClick={this.handleItemClick}
      >
        Въпроси и контакти
      </Menu.Item>
      <Menu.Item
        name="signUp"
        position="right"
        link
        href={'/signup'}
        color="blue"
        style={{ color: 'white' }}
      >
        <Button style={{ backgroundColor: '#2185d0', color: 'white' }}>
          Регистрация
        </Button>
      </Menu.Item>
    </Menu.Menu>,
  ];

  handleSubmit = (e) => {
    this.setState({ isLoading: true });

    const { email, password } = this.state;

    const body = JSON.stringify({ email, password });

    API.post('auth', { body }).then(({ json: data, response }) => {
      if (response.ok) {
        const domain = getCookieDomain();
        Cookies.set('OAUTH_TOKEN', data.accessToken, { expires: 1, domain }); //, secure: !global.__DEV__});
        Cookies.set('USER_ID', data.id, { expires: 1, domain }); //, secure: !global.__DEV__});
        Cookies.set('S', data.schema, { expires: 1, domain });
        this.props.router.push('/consultations');
      } else {
        this.setState({ password: '', error: data.error, isLoading: false });
      }
    });
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value.toLowerCase() });
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  handleForgottenPassword = (e) =>
    this.setState({ isPasswordModalShown: true });

  handleForgottenPasswordClick = e => {
    const { email } = this.state;

    const body = JSON.stringify({ email });

    API.post('forgottenPassword', { body }).then(({ json: data, response }) => {
      if (response.ok) {
        this.setState({ isPasswordModalShown: false, error: null });
      } else {
        this.setState({ forgottenPasswordError: data.error });
      }
    });
  };

  handleUpdatedWidth = (e, { width }) => {
    this.setState({ width });
  };

  render() {
    const {
      email,
      password,
      error,
      isPasswordModalShown,
      width,
      forgottenPasswordError,
    } = this.state;
    // const activeItem = "";
    const isMobile = width && width < 1031;

    const {
      displayName,
      desktopBackground,
      mobileBackground,
    } = this.props.viewer.organization;

    return (
      <ResponsiveContainer
        style={{
          flex: 1,
          minHeight: '100vh',
          // paddingBottom: 82,
          // border: 5px red solid;
          overflow: 'hidden',
        }}
        menuItems={this.menuItems}
        onUpdate={this.handleUpdatedWidth}
      >
        <div>
          <Grid
            style={{
              margin: 0,
              paddingLeft: isMobile ? 0 : 50,
              flex: 1
            }}
            textAlign="center"
            verticalAlign="middle"
            centered
            columns={2}
          >
            <Grid.Column width={isMobile ? 16 : 8}>
              <Grid
                textAlign={isMobile ? 'center' : 'left'}
                style={{ minHeight: 'calc(100vh - 72px)' }}
                verticalAlign="top"
              >
                <Grid.Column style={{ maxWidth: 400 }}>
                  {!error && !isMobile && (
                    <Header
                      style={{
                        // position: 'absolute',
                        marginTop: '30px',
                        marginBottom: '30px',
                        textAlign: 'left',
                        // top: '-160px',
                        fontSize: 50,
                      }}
                    >
                      Добре дошли
                    </Header>
                  )}
                  {!error && !isMobile && (
                    <p
                      style={{
                        // position: 'absolute',
                        marginBottom: '30px',
                        textAlign: 'left',
                        width: '700px',
                        // top: '-100px',
                        fontSize: 25,
                        fontWeight: 'medium',
                        color: 'black',
                      }}
                    >
                      Започнете своята онлайн консултация при <br />
                      {displayName} сега
                    </p>
                  )}
                  <Form
                    size="large"
                    onSubmit={this.handleSubmit}
                    error={!!error}
                    style={{ paddingTop: 20 }}
                  >
                    <Segment stacked>
                      <Header
                        as="h2"
                        color="blue"
                        textAlign="center"
                        style={{ paddingBottom: 8, paddingTop: 8 }}
                      >
                        Вход
                      </Header>
                      <Message
                        error
                        header="Грешно потребителско име или парола"
                        content="Моля опитайте пак."
                      />
                      <Form.Input
                        fluid
                        value={email}
                        icon="user"
                        iconPosition="left"
                        placeholder="Имейл адрес"
                        onChange={this.handleEmailChange}
                        autoComplete="username"
                      />
                      <Form.Input
                        fluid
                        icon="lock"
                        value={password}
                        iconPosition="left"
                        placeholder="Парола"
                        type="password"
                        onChange={this.handlePasswordChange}
                        autoComplete="current-password"
                      />
                      <Button
                        color="blue"
                        fluid
                        size="large"
                        type="submit"
                        loading={this.state.isLoading}
                      >
                        Влез
                      </Button>
                      {error && (
                        <Message attached="bottom" style={{ marginTop: 10 }}>
                          <a href="#" onClick={this.handleForgottenPassword}>
                            Забравена парола?
                          </a>
                        </Message>
                      )}
                    </Segment>
                  </Form>
                  <Message>
                    Нямате акаунт? <a href="/signup">Регистрирайте се тук!</a>
                  </Message>
                </Grid.Column>
              </Grid>
            </Grid.Column>
            {!isMobile && <Grid.Column width={8}></Grid.Column>}
          </Grid>
        </div>
        <FullPageImage imageUrl={isMobile ? mobileBackground : desktopBackground}/>
        <Modal
          open={isPasswordModalShown}
          size={'small'}
          closeIcon
          closeOnDimmerClick={false}
          onClose={() => this.setState({ isPasswordModalShown: false })}
        >
          <Modal.Header>Забравена парола</Modal.Header>
          <Modal.Content>
            {forgottenPasswordError && (
              <Message
                style={{ marginBottom: '10px' }}
                error
                header="Невалиден имейл"
                content="Моля опитайте пак."
              />
            )}
            Моля въведете имейла си и ще получите линк с инструкции за
            рестартиране на вашата парола.
          </Modal.Content>

          <Input
            action={{
              color: 'yellow',
              labelPosition: 'right',
              icon: 'send',
              content: 'Изпрати',
              onClick: this.handleForgottenPasswordClick,
            }}
            value={email}
            type="email"
            placeholder="Имейл"
            style={{ width: '100%', padding: '0 20px 10px 20px' }}
            onChange={this.handleEmailChange}
          />
        </Modal>
        <CookieBanner />
      </ResponsiveContainer>
    );
  }
}

export default createFragmentContainer(
  Login,
  graphql`
    fragment Login_viewer on User {
      organization {
        displayName
        desktopBackground
        mobileBackground
      }
    }
  `
);
