import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "../relay";
// import SharedUpdater from '../helpers/SharedUpdater';

const mutation = graphql`
  mutation UpdateThreadMutation($input: UpdateThreadInput!) {
    updateThread(input: $input) {
      thread {
        ...Consultation_thread
      }
    }
  }
`;

export default (payload, success, failure) => {
  const { input } = payload;

  commitMutation(environment, {
    mutation,
    variables: {
      input
    },
    onCompleted: (response, errors) =>
      errors && errors.length ? failure(errors) : success(response),
    onError: err => failure(err)
  });
};
