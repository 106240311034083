import { Redirect, Route, makeRouteConfig } from 'found';
import Cookies from 'js-cookie';
import React from 'react';

import App from './components/App';
import Consultations from './components/Consultations';
// import ConsultationThread from "./components/ConsultationThread";
import ConsultationForm from './components/ConsultationForm';
import Checkout from './components/Checkout';
import Login from './components/Login';
import MyProfile from './components/MyProfile';
import SignUp from './components/SignUp';
import ErrorPayment from './components/ErrorPayment';
import Faq from './components/Faq';
import SuccessPayment from './components/SuccessPayment';
import Payments from './components/Payments/Payments';
import Patients from './components/Patients/Patients';
import PatientView from './components/Patients/PatientView';
import { getCookieDomain, getSubdomain } from './helpers';
import { Dimmer, Loader } from 'semantic-ui-react';

import {
  AppQuery,
  CheckoutQuery,
  ConsultationFormQuery,
  ConsultationsQuery,
  LoginQuery,
  MyProfileQuery,
  PatientsQuery,
  PatientViewQuery,
  PaymentsQuery,
} from './queries';

const requireLogin = ({ Component, props, data, match, ...rest }) => {
  const accessToken = Cookies.get('OAUTH_TOKEN') || null;

  if (!accessToken) {
    match.router.replace('/login');
  }

  if (!props) {
    props = {
      viewer: null,
    };
  }

  return <Component {...match} {...props} />;
};

const loginIfToken = ({ Component, props, data, match }) => {
  const currentLocation = match.location.pathname;
  const accessToken = Cookies.get('OAUTH_TOKEN') || null;
  const schema = Cookies.get('S') || null;
  const subdomain = getSubdomain();
  if (process.env.NODE_ENV === 'production' && schema && schema !== subdomain) {
    window.location.replace('https://' + schema + '.onlinedocpanel.com');
  }
  if (
    accessToken &&
    (currentLocation === '/' || currentLocation === '/login')
  ) {
    // HACK: We have hardcoded the default logged in route, otherwise at / we will see the <Route Component={Login} />
    match.router.replace('/consultations');
  }

  return null;
  // // HACK: Make sure there is a viewer
  // props = {
  //   viewer: null,
  //   ...props
  // };

  // console.log("component", Component);

  // return <Component {...match} {...props} />;
};

const logout = ({ Component, props, data, match }) => {
  const domain = getCookieDomain();

  // Clear local and session storage
  localStorage.clear();
  sessionStorage.clear();

  // Remove all Cookies
  Object.keys(Cookies.get()).forEach(
    (key) => key !== 'accepts-cookies' && Cookies.remove(key, { domain })
  );

  match.router.replace('/');

  return null;
};

const renderIfAdmin = ({ Component, props, data, match, error }) => {
  if (error) {
    const { viewer } = error.res.data;
    if (!viewer || viewer.type !== 'admin') {
      match.router.replace('/');
    }
    const { message } = error.res.errors[0];
    return <div>{message}</div>;
  } else if (!props) {
    return (
      <Dimmer active inverted style={{ marginTop: 72 }}>
        <Loader>Моля изчакайте</Loader>
      </Dimmer>
    );
  } else {
    return <Component {...props} {...match} />;
  }
};

export default makeRouteConfig(
  <Route path="/" render={loginIfToken}>
    <Route Component={Login} query={LoginQuery} />
    <Route path="faq" Component={Faq} />
    <Route path="signup" Component={SignUp} />
    <Route path="login" Component={Login} query={LoginQuery} />
    <Route path="logout" render={logout} />
    <Route Component={App} query={AppQuery} render={requireLogin}>
      <Route
        path="patients"
        Component={Patients}
        query={PatientsQuery}
        prepareVariables={Patients.getInitialVariables}
        render={renderIfAdmin}
      />
      <Route
        path="patients/:userId"
        Component={PatientView}
        query={PatientViewQuery}
        prepareVariables={PatientView.getInitialVariables}
        render={renderIfAdmin}
      />
      <Redirect from="/" to="/consultations" />
      <Route path="myprofile" Component={MyProfile} query={MyProfileQuery} />

      <Route
        path="checkout"
        Component={Checkout}
        query={CheckoutQuery}
        // prepareVariables={Checkout.getInitialVariables}
      />

      <Route
        path="consultations/create/:paymentId"
        Component={ConsultationForm}
        query={ConsultationFormQuery}
      />
      <Route
        path="consultations"
        Component={Consultations}
        query={ConsultationsQuery}
      />
      <Route
        path="/payments/:paymentId?"
        Component={Payments}
        query={PaymentsQuery}
        render={renderIfAdmin}
        prepareVariables={Payments.getInitialVariables}
      />
      {/* <Route
        path="consultations/:threadId"
        Component={ConsultationThread}
        query={ConsultationThreadQuery}
        prepareVariables={ConsultationThread.getInitialVariables}
      /> */}

      <Route path="errorPayment" Component={ErrorPayment} />
      <Route path="successPayment/:paymentId" Component={SuccessPayment} />
    </Route>
  </Route>
);
