/**
 * @flow
 * @relayHash 04232f5dc1eeb0af55d5bd4b094cccd2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Patients_viewer$ref = any;
export type UsersFilterInput = {
  type?: ?string,
  searchWord?: ?string,
};
export type PatientsRefetchQueryVariables = {|
  filterBy?: ?UsersFilterInput,
  orderBy?: ?$ReadOnlyArray<?$ReadOnlyArray<?string>>,
  limit?: ?number,
  page?: ?number,
  first?: ?number,
|};
export type PatientsRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: Patients_viewer$ref
  |}
|};
export type PatientsRefetchQuery = {|
  variables: PatientsRefetchQueryVariables,
  response: PatientsRefetchQueryResponse,
|};
*/


/*
query PatientsRefetchQuery(
  $filterBy: UsersFilterInput
  $orderBy: [[String]]
  $limit: Int
  $page: Int
  $first: Int
) {
  viewer {
    ...Patients_viewer_18RA4p
    id
  }
}

fragment Patients_viewer_18RA4p on User {
  users(filterBy: $filterBy, orderBy: $orderBy, page: $page, first: $first, limit: $limit) {
    edges {
      node {
        id
        rowId
        ...PatientRow_patient
        __typename
      }
      cursor
    }
    currentPage
    start
    end
    total
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment PatientRow_patient on User {
  id
  rowId
  firstName
  lastName
  phoneNumber
  email
  createdAt
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "filterBy",
    "type": "UsersFilterInput",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[[String]]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "limit",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "page",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filterBy",
    "variableName": "filterBy",
    "type": "UsersFilterInput"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy",
    "type": "[[String]]"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page",
    "type": "Int"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "PatientsRefetchQuery",
  "id": null,
  "text": "query PatientsRefetchQuery(\n  $filterBy: UsersFilterInput\n  $orderBy: [[String]]\n  $limit: Int\n  $page: Int\n  $first: Int\n) {\n  viewer {\n    ...Patients_viewer_18RA4p\n    id\n  }\n}\n\nfragment Patients_viewer_18RA4p on User {\n  users(filterBy: $filterBy, orderBy: $orderBy, page: $page, first: $first, limit: $limit) {\n    edges {\n      node {\n        id\n        rowId\n        ...PatientRow_patient\n        __typename\n      }\n      cursor\n    }\n    currentPage\n    start\n    end\n    total\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment PatientRow_patient on User {\n  id\n  rowId\n  firstName\n  lastName\n  phoneNumber\n  email\n  createdAt\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "PatientsRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Patients_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "filterBy",
                "variableName": "filterBy",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "limit",
                "variableName": "limit",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "orderBy",
                "variableName": "orderBy",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "page",
                "variableName": "page",
                "type": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PatientsRefetchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "users",
            "storageKey": null,
            "args": v1,
            "concreteType": "UserConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "rowId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "phoneNumber",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "currentPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "start",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "end",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "total",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "UFPageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "users",
            "args": v1,
            "handle": "connection",
            "key": "Patient_users",
            "filters": [
              "filterBy"
            ]
          },
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '81fe6f1605dc277d5ce65584370bc021';
module.exports = node;
