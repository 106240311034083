import graphql from "babel-plugin-relay/macro";
import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import { createFragmentContainer } from "react-relay";
import moment from "moment";

class PaymentsRow extends Component {
  render() {
    const { payment } = this.props;
    const { rowId, status, user, type, createdAt } = payment;
    const userName = user ? `${user.firstName} ${user.lastName}` : "";
    const createdAtFormatted = moment(createdAt).format("DD.MM.YYYY HH:mm");

    const formattedStatus =
      status === "00" ? "Успешна" : `Неуспешна (код: ${status})`;

    return (
      <Table.Row>
        <Table.Cell>{rowId}</Table.Cell>
        <Table.Cell>{formattedStatus}</Table.Cell>
        <Table.Cell>{userName}</Table.Cell>
        <Table.Cell>{type}</Table.Cell>
        <Table.Cell>{createdAtFormatted}</Table.Cell>
      </Table.Row>
    );
  }
}

export default createFragmentContainer(
  PaymentsRow,
  graphql`
    fragment PaymentRow_payment on Payment {
      id
      rowId
      status
      user {
        firstName
        lastName
      }
      type
      amount
      createdAt
    }
  `
);
