import React, { Component } from "react";
import { Sidebar, Dimmer, Loader } from "semantic-ui-react";

class SidebarPlaceholder extends Component {
  render() {
    return (
      <Sidebar
        direction="left"
        animation="push"
        style={{ backgroundColor: "white", overflow: "hidden", padding: 40 }}
        icon="labeled"
        visible={true}
        onHide={this.props.onHide}
        vertical="true"
        width={"wide"}
      >
        <Dimmer active inverted>
          <Loader inverted>Моля изчакайте</Loader>
        </Dimmer>
      </Sidebar>
    );
  }
}

export default SidebarPlaceholder;
