import graphql from "babel-plugin-relay/macro";

const PatientViewQuery = graphql`
  query PatientViewQuery(
    $userId: ID
    $threadFilterBy: ThreadFilterInput
    $filesFilterBy: PatientFilesFilterInput
    $paymentsFilterBy: PaymentFilterInput
    $threadOrderBy: [[String]]
    $filesOrderBy: [[String]]
    $paymentsOrderBy: [[String]]
  ) {
    viewer {
      ...PatientView_viewer
        @arguments(
          userId: $userId
          threadFilterBy: $threadFilterBy
          filesFilterBy: $filesFilterBy
          paymentsFilterBy: $paymentsFilterBy
          threadOrderBy: $threadOrderBy
          filesOrderBy: $filesOrderBy
          paymentsOrderBy: $paymentsOrderBy
        )
    }
  }
`;

export default PatientViewQuery;
