/**
 * @flow
 * @relayHash 395a7f9c6ebb1204abb135f6e4874948
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateResponseInput = {
  id: string,
  threadId: string,
  text?: ?string,
  clientMutationId?: ?string,
};
export type UpdateResponseMutationVariables = {|
  input: UpdateResponseInput
|};
export type UpdateResponseMutationResponse = {|
  +updateResponse: ?{|
    +response: ?{|
      +id: string,
      +updatedAt: ?any,
      +text: ?string,
    |}
  |}
|};
export type UpdateResponseMutation = {|
  variables: UpdateResponseMutationVariables,
  response: UpdateResponseMutationResponse,
|};
*/


/*
mutation UpdateResponseMutation(
  $input: UpdateResponseInput!
) {
  updateResponse(input: $input) {
    response {
      id
      updatedAt
      text
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateResponseInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateResponse",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateResponseInput!"
      }
    ],
    "concreteType": "UpdateResponsePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "response",
        "storageKey": null,
        "args": null,
        "concreteType": "Response",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "text",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateResponseMutation",
  "id": null,
  "text": "mutation UpdateResponseMutation(\n  $input: UpdateResponseInput!\n) {\n  updateResponse(input: $input) {\n    response {\n      id\n      updatedAt\n      text\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateResponseMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateResponseMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ba57c899ca8b2f2eda51f47b353d66f2';
module.exports = node;
