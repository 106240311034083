import React from "react";
import styled from "styled-components";
import { TextArea, Divider, Header, Form } from "semantic-ui-react";

import { CreateNoteMutation, UpdateNoteMutation } from "../../mutations";

const Container = styled.div`
  width: calc(100vw - 500px);
  min-width: 300px;
  min-height: calc(100vh - 93px);
  margin-top: 10px;
`;

class Note extends React.Component {
  state = {
    text: this.props.note.text,
    title: this.props.note.title,
    isSubmitting: false
  };

  handleBlur = () => {
    const { text, title } = this.state;
    if (!text.length && !title.length) {
      return;
    }

    if (!this.props.note) {
      this.setState({ isSubmitting: true });

      const { note, viewer } = this.props;
      const input = {
        text,
        title
      };

      const connectionName = "NotesSidebar_patientNotes";
      const filters = { filterBy: { noteId: note.id } };

      const onSuccess = () => {
        console.log("onSuccess");
        this.setState({ isSubmitting: false });
      };

      const onFailure = e => {
        console.log("onFailure", e);

        this.setState({ isSubmitting: false });
      };

      CreateNoteMutation(
        {
          input,
          connectionName,
          viewer,
          filters
        },
        onSuccess,
        onFailure
      );
    } else {
      this.setState({ isSubmitting: true });

      const { note, viewer } = this.props;
      const input = {
        id: this.props.note.id,
        text,
        title
      };

      const connectionName = "NotesSidebar_patientNotes";
      const filters = { filterBy: { noteId: note.id } };

      const onSuccess = () => {
        console.log("onSuccess");
        this.setState({ isSubmitting: false });
      };

      const onFailure = () => {
        console.log("onFailure");

        this.setState({ isSubmitting: false });
      };

      UpdateNoteMutation(
        {
          input,
          connectionName,
          viewer,
          filters
        },
        onSuccess,
        onFailure
      );
    }
  };

  render() {
    const { height } = this.props;

    return (
      <Container>
        <Form style={{ marginLeft: 50 }}>
          <Header>
            <TextArea
              rows={1}
              placeholder="Заглавие"
              style={{
                borderStyle: "none",
                textAlign: "left",
                marginBottom: -12,
                marginLeft: -6,
                fontWeight: "bold"
              }}
              onBlur={this.handleBlur}
              onChange={(e, { value }) => this.setState({ title: value })}
              value={this.state.title}
            />
          </Header>
          <Divider />
          <TextArea
            placeholder="Текст"
            style={{ minHeight: height - 200, borderStyle: "none" }}
            onBlur={this.handleBlur}
            onChange={(e, { value }) => this.setState({ text: value })}
            value={this.state.text}
          />
        </Form>
      </Container>
    );
  }
}

export default Note;
