import graphql from "babel-plugin-relay/macro";

const ConsultationFormQuery = graphql`
  query ConsultationFormQuery_Query($paymentId: String) {
    viewer {
      ...ConsultationForm_viewer
    }
  }
`;

export default ConsultationFormQuery;
