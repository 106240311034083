import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "../relay";

const mutation = graphql`
  mutation UpdateNoteMutation($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      note {
        id
        title
        updatedAt
        text
      }
    }
  }
`;

export default (payload, success, failure) => {
  const { input } = payload;

  commitMutation(environment, {
    mutation,
    variables: {
      input
    },
    onCompleted: (response, errors) =>
      errors && errors.length ? failure(errors) : success(response),
    onError: err => failure(err)
  });
};
