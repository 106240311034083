/* eslint-disable jsx-a11y/anchor-is-valid */
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'found';
import { get } from 'lodash';
import React, { Component } from 'react';
import { createFragmentContainer } from 'react-relay';
import styled from 'styled-components';
import {
  Button,
  Divider,
  Grid,
  Icon,
  Image,
  Header,
  Transition,
  Modal,
  Form,
} from 'semantic-ui-react';

import API from '../helpers/api';
import { CreatePaymentMutation } from '../mutations';

import styles from './Checkout.module.css';

const Container = styled.div`
  min-height: calc(100vh - 72px);
  overflow: hidden;
`;

class Checkout extends Component {
  state = {
    serviceId: null,
    isReceiptModalShown: false,
    isSubmitting: false,
    receiptNumber: '',
    userEmail: '',
    width: null,
  };

  constructor(props) {
    super(props);

    this.state.width = window.innerWidth;

    const unprocessedPayments = get(props, 'viewer.unprocessedPayments') || [];
    if (unprocessedPayments.length) {
      const paymentId = unprocessedPayments[0].id;
      props.router.push(`/consultations/create/${paymentId}`);
    }
  }

  handleReceiptNumberSubmit = () => {
    const { receiptNumber, serviceId, userEmail } = this.state;
    if (!receiptNumber || !receiptNumber.length) {
      return;
    }

    const onSuccess = (data) => {
      const { id } = data.createPayment.payment;
      this.props.router.push(`/consultations/create/${id}`);
    };

    const onFailure = (error) => {
      console.log('onFailure', error);
      this.setState({ isReceiptModalShown: false });
    };

    const input = {
      userEmail,
      serviceId,
      refId: receiptNumber,
      type: 'POS',
    };

    CreatePaymentMutation(input, onSuccess, onFailure);
  };

  handlePayButton = () => {
    const { serviceId } = this.state;
    const isAdmin = this.props.viewer.type === 'admin';

    if (isAdmin) {
      this.setState({ isReceiptModalShown: true });
    } else {
      const body = JSON.stringify({ serviceId });

      API.post('newPayment', { body }).then(async ({ json, response }) => {
        if (response.ok) {
          const { paymentInfo } = json;

          function postForm(path, params, method) {
            method = method || 'post';

            const form = document.createElement('form');
            form.setAttribute('method', method);
            form.setAttribute('action', path);

            for (let key in params) {
              if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.setAttribute('type', 'hidden');
                hiddenField.setAttribute('name', key);
                hiddenField.setAttribute('value', params[key]);

                form.appendChild(hiddenField);
              }
            }

            document.body.appendChild(form);
            form.submit();
          }

          postForm(
            process.env.NODE_ENV === 'production'
              ? 'https://3dsgate.borica.bg/cgi-bin/cgi_link'
              : 'https://3dsgate-dev.borica.bg/cgi-bin/cgi_link',
            paymentInfo
          );
        }
      });
    }
  };

  handleFieldChange = (e, data) => {
    this.setState({ [data.name]: data.value });
  };

  renderServices = (services) =>
    services.map((service) => (
      <Grid.Column
        key={service.id}
        className={styles.container}
        onClick={() => this.setState({ serviceId: service.id })}
      >
        <Image src={service.imageUrl} />
        <p style={{ textAlign: 'center', paddingTop: 10 }}>{service.name}</p>
      </Grid.Column>
    ));

  renderCheckout = () => {
    const isAdmin = this.props.viewer.type === 'admin';
    const {
      isSubmitting,
      isReceiptModalShown,
      receiptNumber,
      userEmail,
    } = this.state;

    const visible = this.state.serviceId !== null;
    const selectedService =
      this.props.viewer.services.find((s) => s.id === this.state.serviceId) ||
      {};

    const formattedAmount =
      selectedService.amount && selectedService.amount.toFixed(2);

    return (
      <Transition visible={visible} animation="fade down" duration={1250}>
        <Grid
          centered
          style={{
            height: '100%',
            paddingTop: 50,
            paddingLeft: 50,
            paddingRight: 50,
          }}
        >
          <Grid.Column style={{ maxWidth: 800 }}>
            {/* TODO: Sub this with block inline, etc. */}
            <Grid columns="equal">
              <Grid.Column floated="left" width={8}>
                <Header>{selectedService.description}</Header>
              </Grid.Column>
              <Grid.Column floated="left" width={8} style={{ paddingLeft: 0 }}>
                <a href="#" onClick={() => this.setState({ serviceId: null })}>
                  Промени
                </a>
              </Grid.Column>
            </Grid>
            <Grid celled>
              <Grid.Row>
                <Grid.Column width={5}>
                  <Image src={selectedService.imageUrl} />
                </Grid.Column>
                <Grid.Column width={11}>
                  <p>
                    Вие избрахте опция "{`${selectedService.description}`}
                    ". За да продължите, моля направете плащане с кредитна или
                    дебитна карта. Натиснете бутона{' '}
                    <span style={{ fontWeight: 'bold' }}>"Плащане"</span> и
                    попълнете Вашата информация на следващия екран. При успешно
                    плащане, ще можете да започнете Вашата консултация.
                  </p>
                  <Divider />
                  <Grid.Row>
                    <Grid relaxed="very" columns={2}>
                      <Grid.Column>
                        <span style={{ fontWeight: 'bold' }}>
                          1x Онлайн Консултация
                          <p>Общо</p>
                          <p>Начин на плащане</p>
                        </span>
                      </Grid.Column>
                      <Grid.Column textAlign="right">
                        {/* TODO: Sub with service.amount and format */}
                        <span style={{ fontWeight: 'bold' }}>
                          {formattedAmount} лв
                          <p>{formattedAmount} лв</p>
                          <p>{isAdmin ? 'Касово' : 'Онлайн'}</p>
                        </span>
                      </Grid.Column>
                    </Grid>
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Button
              animated="fade"
              color="green"
              style={{ width: '100%' }}
              onClick={this.handlePayButton}
            >
              <Button.Content visible>
                {isAdmin ? 'Продължи' : 'Плащане'}
              </Button.Content>
              <Button.Content hidden>
                <Icon name="arrow right" />
              </Button.Content>
            </Button>
          </Grid.Column>
          <Modal
            open={isReceiptModalShown}
            size={'small'}
            style={{ height: 200 }}
            closeIcon
            closeOnDimmerClick={false}
            onClose={() => this.setState({ isReceiptModalShown: false })}
          >
            <Modal.Header>Касово Плащане</Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Input
                  fluid
                  name="receiptNumber"
                  label="Номер на касова бележка"
                  placeholder="#"
                  onChange={this.handleFieldChange}
                  value={receiptNumber}
                  required
                />
                <Form.Input
                  fluid
                  name="userEmail"
                  label="Имейл"
                  placeholder="Имейл"
                  onChange={this.handleFieldChange}
                  value={userEmail}
                  required
                />
              </Form>
            </Modal.Content>
            <Modal.Actions
              actions={[
                {
                  key: 'enter',
                  color: 'blue',
                  labelPosition: 'right',
                  icon: 'file alternate outline',
                  content: 'Въведи',
                  onClick: this.handleReceiptNumberSubmit,
                  disabled: !receiptNumber || !userEmail,
                  loading: isSubmitting,
                },
              ]}
            />
          </Modal>
        </Grid>
      </Transition>
    );
  };

  renderPickConsultationType = () => {
    const visible = this.state.serviceId === null;
    const { services } = this.props.viewer;

    return (
      <Transition visible={visible} animation="fade up" duration={500}>
        <Grid
          centered
          style={{
            height: '100%',
            paddingTop: 50,
            paddingLeft: 50,
            paddingRight: 50,
          }}
        >
          <Grid.Column style={{ maxWidth: 600 }}>
            <Header textAlign="center">
              Моля изберете тип на консултацията:{' '}
            </Header>
            <Grid celled>
              <Grid.Row columns={services.length}>
                {this.renderServices(services)}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid>
      </Transition>
    );
  };

  render() {
    // You chose ...       Summary with photo
    // (change)

    //<Segment>
    // Total of ...
    // Details about online consultation (optional)

    //<Button
    // Pay with credit card to start your online consultatio now

    return (
      <Container>
        {this.renderPickConsultationType()}
        {this.renderCheckout()}
      </Container>
    );
  }
}

export default createFragmentContainer(
  withRouter(Checkout),
  graphql`
    fragment Checkout_viewer on User {
      id
      type
      services {
        id
        name
        description
        amount
        imageUrl
      }
      unprocessedPayments {
        id
        rowId
      }
    }
  `
);
