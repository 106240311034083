/**
 * @flow
 * @relayHash a9d7bd732be30d26349f2af8498e8b5f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PatientView_viewer$ref = any;
export type ThreadFilterInput = {
  userId?: ?string,
  isActive?: ?boolean,
};
export type PatientFilesFilterInput = {
  userId?: ?string
};
export type PaymentFilterInput = {
  date?: ?RangeDateFilter,
  type?: ?string,
  status?: ?string,
  paymentId?: ?string,
  userId?: ?string,
};
export type RangeDateFilter = {
  from?: ?string,
  to?: ?string,
};
export type PatientViewRefetchQueryVariables = {|
  userId?: ?string,
  threadFilterBy?: ?ThreadFilterInput,
  filesFilterBy?: ?PatientFilesFilterInput,
  paymentsFilterBy?: ?PaymentFilterInput,
  threadOrderBy?: ?$ReadOnlyArray<?$ReadOnlyArray<?string>>,
  filesOrderBy?: ?$ReadOnlyArray<?$ReadOnlyArray<?string>>,
  paymentsOrderBy?: ?$ReadOnlyArray<?$ReadOnlyArray<?string>>,
|};
export type PatientViewRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: PatientView_viewer$ref
  |}
|};
export type PatientViewRefetchQuery = {|
  variables: PatientViewRefetchQueryVariables,
  response: PatientViewRefetchQueryResponse,
|};
*/


/*
query PatientViewRefetchQuery(
  $userId: ID
  $threadFilterBy: ThreadFilterInput
  $filesFilterBy: PatientFilesFilterInput
  $paymentsFilterBy: PaymentFilterInput
  $threadOrderBy: [[String]]
  $filesOrderBy: [[String]]
  $paymentsOrderBy: [[String]]
) {
  viewer {
    ...PatientView_viewer_1tey2D
    id
  }
}

fragment PatientView_viewer_1tey2D on User {
  id
  user(id: $userId) {
    id
    threads(filterBy: $threadFilterBy, orderBy: $threadOrderBy) {
      ...Threads_threads
    }
    patientFiles(filterBy: $filesFilterBy, orderBy: $filesOrderBy) {
      ...Files_files
    }
    payments(filterBy: $paymentsFilterBy, orderBy: $paymentsOrderBy) {
      ...Payments_payments
    }
    ...PatientProfile_patient
  }
}

fragment Threads_threads on ThreadConnection {
  edges {
    node {
      id
      rowId
      title
      createdAt
      type
      isActive
      paymentId
      ...Consultation_thread
    }
  }
}

fragment Files_files on FileConnection {
  edges {
    node {
      rowId
      name
      createdAt
      id
    }
  }
}

fragment Payments_payments on PaymentConnection {
  edges {
    node {
      rowId
      amount
      service {
        name
        id
      }
      createdAt
      isSuccessful
      id
    }
  }
}

fragment PatientProfile_patient on User {
  id
  rowId
  firstName
  lastName
  phoneNumber
  createdAt
  dateOfBirth
  gender
  email
}

fragment Consultation_thread on Thread {
  id
  service {
    description
    id
  }
  user {
    id
    rowId
    firstName
    lastName
    email
    phoneNumber
  }
  createdAt
  deactivatedAt
  title
  isActive
  payment {
    id
    rowId
    refId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "threadFilterBy",
    "type": "ThreadFilterInput",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filesFilterBy",
    "type": "PatientFilesFilterInput",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "paymentsFilterBy",
    "type": "PaymentFilterInput",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "threadOrderBy",
    "type": "[[String]]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filesOrderBy",
    "type": "[[String]]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "paymentsOrderBy",
    "type": "[[String]]",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phoneNumber",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "PatientViewRefetchQuery",
  "id": null,
  "text": "query PatientViewRefetchQuery(\n  $userId: ID\n  $threadFilterBy: ThreadFilterInput\n  $filesFilterBy: PatientFilesFilterInput\n  $paymentsFilterBy: PaymentFilterInput\n  $threadOrderBy: [[String]]\n  $filesOrderBy: [[String]]\n  $paymentsOrderBy: [[String]]\n) {\n  viewer {\n    ...PatientView_viewer_1tey2D\n    id\n  }\n}\n\nfragment PatientView_viewer_1tey2D on User {\n  id\n  user(id: $userId) {\n    id\n    threads(filterBy: $threadFilterBy, orderBy: $threadOrderBy) {\n      ...Threads_threads\n    }\n    patientFiles(filterBy: $filesFilterBy, orderBy: $filesOrderBy) {\n      ...Files_files\n    }\n    payments(filterBy: $paymentsFilterBy, orderBy: $paymentsOrderBy) {\n      ...Payments_payments\n    }\n    ...PatientProfile_patient\n  }\n}\n\nfragment Threads_threads on ThreadConnection {\n  edges {\n    node {\n      id\n      rowId\n      title\n      createdAt\n      type\n      isActive\n      paymentId\n      ...Consultation_thread\n    }\n  }\n}\n\nfragment Files_files on FileConnection {\n  edges {\n    node {\n      rowId\n      name\n      createdAt\n      id\n    }\n  }\n}\n\nfragment Payments_payments on PaymentConnection {\n  edges {\n    node {\n      rowId\n      amount\n      service {\n        name\n        id\n      }\n      createdAt\n      isSuccessful\n      id\n    }\n  }\n}\n\nfragment PatientProfile_patient on User {\n  id\n  rowId\n  firstName\n  lastName\n  phoneNumber\n  createdAt\n  dateOfBirth\n  gender\n  email\n}\n\nfragment Consultation_thread on Thread {\n  id\n  service {\n    description\n    id\n  }\n  user {\n    id\n    rowId\n    firstName\n    lastName\n    email\n    phoneNumber\n  }\n  createdAt\n  deactivatedAt\n  title\n  isActive\n  payment {\n    id\n    rowId\n    refId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "PatientViewRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "PatientView_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "filesFilterBy",
                "variableName": "filesFilterBy",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "filesOrderBy",
                "variableName": "filesOrderBy",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "paymentsFilterBy",
                "variableName": "paymentsFilterBy",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "paymentsOrderBy",
                "variableName": "paymentsOrderBy",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "threadFilterBy",
                "variableName": "threadFilterBy",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "threadOrderBy",
                "variableName": "threadOrderBy",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "userId",
                "variableName": "userId",
                "type": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PatientViewRefetchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "userId",
                "type": "ID"
              }
            ],
            "concreteType": "User",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "threads",
                "storageKey": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "filterBy",
                    "variableName": "threadFilterBy",
                    "type": "ThreadFilterInput"
                  },
                  {
                    "kind": "Variable",
                    "name": "orderBy",
                    "variableName": "threadOrderBy",
                    "type": "[[String]]"
                  }
                ],
                "concreteType": "ThreadConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ThreadEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Thread",
                        "plural": false,
                        "selections": [
                          v1,
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                          },
                          v3,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "type",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "isActive",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "paymentId",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "service",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Service",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "description",
                                "args": null,
                                "storageKey": null
                              },
                              v1
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": [
                              v1,
                              v2,
                              v4,
                              v5,
                              v6,
                              v7
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "deactivatedAt",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "payment",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Payment",
                            "plural": false,
                            "selections": [
                              v1,
                              v2,
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "refId",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "patientFiles",
                "storageKey": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "filterBy",
                    "variableName": "filesFilterBy",
                    "type": "PatientFilesFilterInput"
                  },
                  {
                    "kind": "Variable",
                    "name": "orderBy",
                    "variableName": "filesOrderBy",
                    "type": "[[String]]"
                  }
                ],
                "concreteType": "FileConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FileEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "File",
                        "plural": false,
                        "selections": [
                          v2,
                          v8,
                          v3,
                          v1
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "payments",
                "storageKey": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "filterBy",
                    "variableName": "paymentsFilterBy",
                    "type": "PaymentFilterInput"
                  },
                  {
                    "kind": "Variable",
                    "name": "orderBy",
                    "variableName": "paymentsOrderBy",
                    "type": "[[String]]"
                  }
                ],
                "concreteType": "PaymentConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PaymentEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Payment",
                        "plural": false,
                        "selections": [
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "amount",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "service",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Service",
                            "plural": false,
                            "selections": [
                              v8,
                              v1
                            ]
                          },
                          v3,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "isSuccessful",
                            "args": null,
                            "storageKey": null
                          },
                          v1
                        ]
                      }
                    ]
                  }
                ]
              },
              v2,
              v4,
              v5,
              v7,
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "dateOfBirth",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "gender",
                "args": null,
                "storageKey": null
              },
              v6
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '129dee1d9dd583c572383046e36d0c58';
module.exports = node;
