import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';

import { environment } from '../relay';
import SharedUpdater from '../helpers/SharedUpdater';

const mutation = graphql`
  mutation CreateNoteMutation($input: CreateNoteInput!) {
    createNote(input: $input) {
      clientMutationId
      noteEdge {
        node {
          id
          rowId
          title
          createdAt
          updatedAt
          text
        }
      }
    }
  }
`;

export default (payload, success, failure) => {
  const { connectionName, input, viewer, filters } = payload;

  commitMutation(environment, {
    mutation,
    variables: {
      input
    },
    updater: store => {
      // Add to connection
      const payload = store.getRootField('createNote');
      if (!payload) {
        return;
      }

      const newEdge = payload.getLinkedRecord('noteEdge');
      SharedUpdater.addEdgeToConnection(
        store,
        viewer.id,
        newEdge,
        connectionName,
        filters
      );
    },
    onCompleted: (response, errors) =>
      errors && errors.length ? failure(errors) : success(response),
    onError: err => failure(err)
  });
};
